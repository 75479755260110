import React from "react";
import { Modal } from "react-bootstrap";

const ModalDeleteNlqExample = ({ show, handleClose, onClickDeleteConfirm, fewshotToDelete }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Delete Example</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to delete <b>&quot;{fewshotToDelete?.prompt || fewshotToDelete?.analysisName}&quot;</b>?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-sm btn-outline-primary" onClick={handleClose}>
          Cancel
        </button>
        <button className="btn btn-sm btn-outline-danger" onClick={onClickDeleteConfirm}>
          Yes, Delete
        </button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalDeleteNlqExample;
