import React, { useEffect, useState, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import Select from "react-select";
import { useIntercom } from "react-use-intercom";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import amplitude from "amplitude-js";
import { useGoogleLogin } from "@react-oauth/google";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  datasources,
  datasourceTypes,
  DATASOURCES_WITH_SSL_CUSTOMCERTS,
  DATASOURCES_WITH_SSHTUNNEL,
  GOOGLE_SHEETS_URL_REGEX,
  SNOWFLAKE_HOSTNAME_REGEX,
  datasourceGoogleSheetSteps,
  datasourceRegularSteps,
  datasourceLiveGoogleSheetSteps,
  datasourceExcelAndCsvSteps,
  queueNames,
  msSqlAuthTypes,
  PricingTiers,
} from "helpers/constants";
import { capitalize } from "helpers/capitalize";
import Actions from "redux/actions/datasource";
import LoginActions from "redux/actions/login";
import QueryManagerActions from "redux/actions/query-manager";
import ProfileActions from "redux/actions/profile";
import Loading from "components/loading";
import Alert from "components/alert";
import DataSourceTables from "components/datasource/tables";
import ModalListGoogleDriveFiles from "components/datasource/list-google-drive-files";
import FileDropZone from "components/datasource/csv/file-dropzone";
import ProFeature from "components/datasource/pro-feature";
import FilesTable from "components/datasource/csv/files-table";
import SuccessMessage from "components/datasource/success-message";
import { getDatasourceObject } from "../../helpers/datasource.helper";
import AuthService from "../../helpers/auth";

const containsHttpsSnowflakeHost = (string) => SNOWFLAKE_HOSTNAME_REGEX.test(string);

const schema = yup.object().shape({
  type: yup.string().required("Type is required"),
  organization: yup.string().optional(),
  host: yup
    .string()
    .when("type", {
      is: (type) => type === datasourceTypes.SNOWFLAKE,
      then: yup
        .string()
        .max(100, "Host cannot be longer than 100 characters")
        .required("Host is required")
        .test(
          "not include https",
          "Do not include the https:// portion of your Snowflake Host",
          (value) => !containsHttpsSnowflakeHost(value)
        ),
    })
    .when("type", {
      is: (type) =>
        type !== datasourceTypes.GOOGLE_BIGQUERY &&
        type !== datasourceTypes.GOOGLE_SHEETS &&
        type !== datasourceTypes.UPLOADED_CSV,
      then: yup.string().max(100, "Host cannot be longer than 100 characters").required("Host is required"),
    }),
  port: yup
    .string()
    .nullable(true)
    .max(20, "Port cannot be longer than 20 characters")
    .matches(/^[0-9]+$/, "Port must be numeric")
    .transform((v, o) => (o === "" ? null : v)),
  username: yup.string().max(100, "Username cannot be longer than 100 characters").optional(),
  password: yup.string().when(["type", "username"], {
    is: (type, username) =>
      type !== datasourceTypes.CLICKHOUSE && type !== datasourceTypes.SNOWFLAKE && username?.length > 0,
    then: yup.string().required("Password is required"),
  }),
  privatekeysnowflake: yup.string().when(["type", "password"], {
    is: (type, password) => type === datasourceTypes.SNOWFLAKE && password?.length <= 0,
    then: yup.string().required("Private Key is required"),
  }),
  databasename: yup.string().when("type", {
    is: (type) =>
      type !== datasourceTypes.GOOGLE_BIGQUERY &&
      type !== datasourceTypes.GOOGLE_SHEETS &&
      type !== datasourceTypes.UPLOADED_CSV &&
      type !== datasourceTypes.TRINO,
    then: yup
      .string()
      .max(100, "Database Name cannot be longer than 100 characters")
      .required("Database Name is required"),
  }),
  displayName: yup.string().when("type", {
    is: (type) => type === datasourceTypes.TRINO,
    then: yup
      .string()
      .max(100, "Display Name cannot be longer than 100 characters")
      .required("Display Name is required"),
  }),
  csvdatabasename: yup.string().when("type", {
    is: (type) => type === datasourceTypes.UPLOADED_CSV,
    then: yup.string().max(50, "Name cannot be longer than 50 characters").required("Name is required"),
  }),
  projectId: yup.string().when("type", {
    is: (type) => type === datasourceTypes.GOOGLE_BIGQUERY,
    then: yup.string().max(100, "Project Id cannot be longer than 100 characters").required("Project Id is required"),
  }),
  datasetId: yup.string().when("type", {
    is: (type) => type === datasourceTypes.GOOGLE_BIGQUERY,
    then: yup.string().max(100, "Dataset Id cannot be longer than 100 characters").required("Dataset Id is required"),
  }),
  location: yup.string().optional("", null),
  serviceAccount: yup.string().when("type", {
    is: (type) => type === datasourceTypes.GOOGLE_BIGQUERY,
    then: yup.string().required("Service Account is required"),
  }),
  sheetUrl: yup.string().when("type", {
    is: (type) => type === datasourceTypes.GOOGLE_SHEETS,
    then: yup.string().required("Sheet URL is required").matches(GOOGLE_SHEETS_URL_REGEX, "Enter a valid url"),
  }),
  tableName: yup.string().when("type", {
    is: (type) => type === datasourceTypes.GOOGLE_SHEETS,
    then: yup.string().required("Table Name is required"),
  }),
  catalog: yup.string().when("type", {
    is: (type) => type === datasourceTypes.TRINO || type === datasourceTypes.PRESTO,
    then: yup.string().required("Catalog is required"),
  }),
  schema: yup.string().when("type", {
    is: (type) => type === datasourceTypes.TRINO || type === datasourceTypes.PRESTO,
    then: yup.string().required("Schema is required"),
  }),
  role: yup.string().optional(),
  ssl: yup.bool().optional(),
  sslwithcerts: yup.bool().optional(),
  sslserverrootcert: yup.string().optional(),
  sslclientkey: yup.string().optional(),
  sslclientcert: yup.string().optional(),
  sshtunnel: yup.bool().optional(),
  sshtunnelusername: yup.string().when("sshtunnel", {
    is: true,
    then: yup
      .string()
      .required("SSH username is required when connecting over SSH")
      .max(100, "SSH username cannot be longer than 100 characters"),
  }),
  sshtunnelpassword: yup.string().when("sshtunnelusername", {
    is: (username) => username.length > 0,
    then: yup.string().optional(),
  }),
  sshprivatekey: yup.string().when("sshtunnel", {
    is: true,
    then: yup.string().required("Private Key is required when connecting over SSH"),
  }),
  sshtunnelport: yup.string().when("sshtunnel", {
    is: true,
    then: yup.string().optional(),
  }),
  httpPath: yup.string().when("type", {
    is: (type) => type === datasourceTypes.DATABRICKS,
    then: yup.string().required("HTTP path is required"),
  }),
  token: yup.string().when(["type", "authType"], {
    is: (type, authType) =>
      type === datasourceTypes.DATABRICKS ||
      (type === datasourceTypes.MICROSOFT_SQL && authType === "azure-active-directory-access-token"),
    then: yup.string().required("Token is required"),
  }),
  authType: yup.string().when("type", {
    is: (type) => type === datasourceTypes.MICROSOFT_SQL,
    then: yup.string().optional(),
  }),
  encrypt: yup.bool().optional(),
  clientId: yup.string().when(["type", "authType"], {
    is: (type, authType) =>
      type === datasourceTypes.MICROSOFT_SQL &&
      (authType === "azure-active-directory-msi-app-service" ||
        authType === "azure-active-directory-msi-vm" ||
        authType === "azure-active-directory-service-principal-secret"),
    then: yup.string().required("Client Id is required"),
  }),
  msiEndpoint: yup.string().when(["type", "authType"], {
    is: (type, authType) =>
      type === datasourceTypes.MICROSOFT_SQL &&
      (authType === "azure-active-directory-msi-app-service" || authType === "azure-active-directory-msi-vm"),
    then: yup.string().required("MSI endpoint is required"),
  }),
  msiSecret: yup.string().when(["type", "authType"], {
    is: (type, authType) =>
      type === datasourceTypes.MICROSOFT_SQL && authType === "azure-active-directory-msi-app-service",
    then: yup.string().required("MSI endpoint is required"),
  }),
  domain: yup.string().when(["type", "authType"], {
    is: (type, authType) =>
      type === datasourceTypes.MICROSOFT_SQL && (authType === "azure-active-directory-password" || authType === "ntlm"),
    then: yup.string().required("Domain is required"),
  }),
  clientSecret: yup.string().when(["type", "authType"], {
    is: (type, authType) =>
      type === datasourceTypes.MICROSOFT_SQL && authType === "azure-active-directory-service-principal-secret",
    then: yup.string().required("Client secret is required"),
  }),
  tenantId: yup.string().when(["type", "authType"], {
    is: (type, authType) =>
      type === datasourceTypes.MICROSOFT_SQL && authType === "azure-active-directory-service-principal-secret",
    then: yup.string().required("Tenant Id is required"),
  }),
  warehouse: yup.string().when("type", {
    is: (type) => type === datasourceTypes.SNOWFLAKE,
    then: yup.string().optional(),
  }),
});

const ModalDataSource = ({ datasourceType, show, handleClose, organizationsByRole }) => {
  // Amplitude
  const instance = amplitude.getInstance();

  const { user } = AuthService.getUser();
  const userAuth = user?._id || localStorage.getItem("userauth");

  // Intercom
  const { update, trackEvent: intercomTrackEvent } = useIntercom();
  const updateWithProps = () => {
    return update({ name: user?.roles?.account?.name, userId: userAuth, updatedAt: new Date(), email: user?.email });
  };

  const organizationsByRoleOptions = useMemo(
    () => organizationsByRole?.map((org) => ({ label: org.name, value: org._id })),
    [organizationsByRole]
  );

  const { register, handleSubmit, errors, control, setValue } = useForm({
    resolver: yupResolver(schema),
  });

  // eslint-disable-next-line no-console
  const onError = (errors, e) => console.log("onError", errors, e);

  const {
    first,
    showSaveSuccess,
    loading,
    datasource: createdDatasource,
    showSaveTablesSuccess,
  } = useSelector((s) => s.datasource);
  const { tokens } = useSelector((s) => s.login);
  const { account } = useSelector((s) => s.settings);
  const { accountData } = useSelector((s) => s.account);

  const {
    loading: loadingQuery,
    loadingCsvUpload,
    loadingCsvJob,
    jobId,
    jobState,
    jobStateIngestion,
    jobStateDbImporter,
    queryResult,
    ingestionValue,
    dbImporterValue,
    type: queueType,
    timeoutError,
    timeoutErrorIngestion,
    timeoutErrorDbImporter,
    error,
    connectionErr,
    loadingCsvImport,
    csvAction,
    refreshingTables,
  } = useSelector((s) => s.queryManager);

  let [step, setStep] = useState(0);
  const [datasource, setDatasource] = useState({});
  const [type, setType] = useState("");
  const [organization, setOrganization] = useState("");
  const [alerts, setAlerts] = useState([]);
  const [includeAll, setIncludeAll] = useState(true);
  const [isLiveConnection, setIsLiveConnection] = useState(false);
  const [tables, setTables] = useState([]);
  const [steps, setSteps] = useState(datasourceRegularSteps);
  const [hasErrorGoogleSheet, setHasErrorGoogleSheet] = useState(false);
  const [showGoogleDriveFilesModal, setShowGoogleDriveFilesModal] = useState(false);
  const [googleAccessToken, setGoogleAccessToken] = useState(null);
  const [googleRefreshToken, setGoogleRefreshToken] = useState(null);
  const [authType] = useState("default");
  const [orgTier, setOrgTier] = useState(PricingTiers.BASIC);
  const [hostWarning, setHostWarning] = useState();
  const [displayName, setDisplayName] = useState("");

  // csv upload v2
  const [files, setFiles] = useState();
  const [filesToImport, setFilesToImport] = useState(dbImporterValue?.files);
  const [filesToSave, setFilesToSave] = useState(dbImporterValue?.csvUpload?.files);
  const [hasFileLimitError, setHasFileLimitError] = useState(false);
  const [tableNamesForCsvImport, setTableNamesForCsvImport] = useState({});
  const [datasourceIdForCsv, setDatasourceIdForCsv] = useState();
  const [hasNameDuplicates, setHasNameDuplicates] = useState(false);

  useEffect(() => {
    if (!dbImporterValue) {
      setFilesToImport();
      setFilesToSave();
      setHasFileLimitError(false);
      setTableNamesForCsvImport({});
    }

    if (dbImporterValue?.files) {
      setFilesToImport(dbImporterValue.files);
    }

    if (dbImporterValue?.csvUpload?.files) {
      setFilesToSave(dbImporterValue.csvUpload.files?.filter((f) => f.included));

      let tablesToSave = dbImporterValue.csvUpload.files.map((table) => {
        const isTableAlreadyIncluded = tables.filter((t) => t.tableName === table.tableName)?.length;
        if (table.included && table.tableName && !isTableAlreadyIncluded) {
          return {
            tableName: table.tableName,
            schemaName: "public",
            labelName: capitalize(table.tableName),
            included: table.included,
          };
        }
      });

      tablesToSave = tablesToSave.filter(Boolean);

      setTables([...tables, ...tablesToSave]);
    }
  }, [dbImporterValue]);

  const handleChange = (event) => {
    setFiles();
    if (event) {
      setDatasourceType(event.value);
    } else {
      setValue("type", "", { shouldValidate: true });
      setType("");
      setSteps(datasourceRegularSteps);
    }
  };

  const handleOrganizationChange = (event) => {
    if (event) {
      setValue("organization", event.value, { shouldValidate: true });
      setOrganization(event.value);
    } else {
      setValue("organization", "", { shouldValidate: true });
      setOrganization("");
    }
  };

  useEffect(() => {
    if (organizationsByRole?.length) {
      setOrganization(organizationsByRole[0] ? organizationsByRole[0]._id : "");
    }
  }, [organizationsByRole]);

  useEffect(() => {
    if (organization && accountData) {
      const org = accountData?.billingPlans.find((plan) => plan.organizationId === organization);
      const orgTier = org?.tier;
      setOrgTier(orgTier);
    }
  }, [organization, accountData]);

  const setDatasourceType = useCallback(
    (type) => {
      setValue("type", type, { shouldValidate: true });
      setType(type);

      if (type === datasourceTypes.POSTGRES || type === datasourceTypes.MYSQL || type === datasourceTypes.CLICKHOUSE) {
        setValue("port", "", { shouldValidate: true });
      } else if (
        type === datasourceTypes.TRINO ||
        type === datasourceTypes.PRESTO ||
        type === datasourceTypes.DATABRICKS
      ) {
        setValue("port", 443, { shouldValidate: true });
      }

      if (type === datasourceTypes.CLICKHOUSE) {
        setValue("databasename", "default", { shouldValidate: true });
      } else {
        setValue("databasename", undefined);
      }

      if (type === datasourceTypes.POSTGRES) {
        setValue("schemaname", "public", { shouldValidate: true });
      } else {
        setValue("schemaname", undefined);
      }

      setValue("warehousename", undefined);

      if (type === datasourceTypes.GOOGLE_SHEETS) {
        setSteps(datasourceGoogleSheetSteps);
      } else if (type === datasourceTypes.UPLOADED_CSV) {
        setSteps(datasourceExcelAndCsvSteps);
      } else {
        setSteps(datasourceRegularSteps);
      }
    },
    [setValue]
  );

  const setAuthTypeValue = useCallback(
    (type) => {
      setValue("authType", type);
    },
    [setValue]
  );

  const toggleIsLiveConnection = (e) => {
    setIsLiveConnection(e.target.checked);

    if (e.target.checked) {
      setSteps(datasourceLiveGoogleSheetSteps);
    } else {
      setSteps(datasourceGoogleSheetSteps);
    }
  };

  const doSave = (data, e) => {
    e.preventDefault();

    // Remove when enabling different auth types
    if (!data.authType) {
      data.authType = "default";
    }

    if (organizationsByRoleOptions.length && !data.organization) {
      data.organization = organizationsByRoleOptions[0].value;
    }

    if (data.sslwithcerts) {
      data.ssl = true;

      if (!data.sslclientcert && !data.sslclientkey && !data.sslserverrootcert) {
        data.sslwithcerts = false;
      }
    }

    if (data.type === datasourceTypes.POSTGRES) {
      if (data.schemaname) {
        data.schema = data.schemaname;
      } else {
        data.schema = "public";
      }

      delete data.schemaname;
    }

    if (data.type === datasourceTypes.SNOWFLAKE) {
      if (data.warehousename) {
        data.warehouse = data.warehousename;
      }

      delete data.warehousename;
    }

    const _datasource = getDatasourceObject(data);
    setDatasource(_datasource);
    setAlerts([]);

    QueryManagerActions.checkConnection({
      datasource: _datasource,
      version: 2,
    });
  };

  useEffect(() => {
    updateWithProps();
  }, []);

  useEffect(() => {
    ProfileActions.getDetails(userAuth);
  }, [userAuth]);

  const doCreateNew = () => {
    datasource.tables = tables.map((table) => ({
      tableName: table?.tableName,
      schemaName: table.schemaName,
      warehouseName: table.warehouseName,
      labelName: table.labelName,
      included: table.included,
      columnSchema: table.columnSchema,
    }));

    datasource.settings = {
      includeAllTables: includeAll,
    };

    datasource.isLiveConnection = isLiveConnection;

    Actions.createNew({ datasource });
  };

  const responseGoogle = async (response) => {
    if (response.access_token) {
      setGoogleAccessToken(response.access_token);
      setShowGoogleDriveFilesModal(true);
    } else {
      LoginActions.getGoogleTokens({
        code: response.code,
      });
    }
  };

  useEffect(() => {
    if (tokens) {
      setGoogleAccessToken(tokens.tokens.access_token);
      setGoogleRefreshToken(tokens.tokens.refresh_token);
      setShowGoogleDriveFilesModal(true);
    }
  }, [tokens]);

  const handleCloseGoogleDriveModal = () => {
    setShowGoogleDriveFilesModal(false);
    Actions.hideDataSourceGoogleDrive();
  };

  const handleSelectGoogleDriveFile = (selectedFile) => {
    let googleSheetDatasource = {
      type: datasourceTypes.GOOGLE_SHEETS,
      displayName,
      fileId: selectedFile.id,
      tableName: selectedFile.name,
      accessToken: googleAccessToken,
      refreshToken: googleRefreshToken,
      isLiveConnection,
      organization,
    };

    if (organizationsByRoleOptions?.length && !organization) {
      googleSheetDatasource.organization = organizationsByRoleOptions[0].value;
    }

    setDatasource(googleSheetDatasource);
    setAlerts([]);

    googleSheetDatasource = {
      ...googleSheetDatasource,
      tables: [],
      settings: {
        includeAllTables: includeAll,
      },
    };

    Actions.createNew({ datasource: googleSheetDatasource });
  };

  const loginGoogleAuthFlow = useGoogleLogin({
    onSuccess: responseGoogle,
    onFailure: onError,
    scope: "https://www.googleapis.com/auth/spreadsheets.readonly https://www.googleapis.com/auth/drive.readonly",
    flow: "auth-code",
    hint: account?.email,
  });

  const loginGoogleImplicitFlow = useGoogleLogin({
    onSuccess: responseGoogle,
    onFailure: onError,
    scope: "https://www.googleapis.com/auth/spreadsheets.readonly https://www.googleapis.com/auth/drive.readonly",
    flow: "implicit",
    prompt: "",
  });

  const accessGoogleSheets = () => {
    if (account?.user?.google?.hasRefreshToken) {
      loginGoogleImplicitFlow();
    } else {
      loginGoogleAuthFlow();
    }
  };

  const doImportGoogleSheet = useCallback(() => {
    setAlerts([]);
    setDatasource(createdDatasource);

    QueryManagerActions.startIngestion({
      datasource: createdDatasource._id,
    });
  }, [createdDatasource]);

  const getSheetNames = async (selectedFile) => {
    try {
      let response;
      if (!googleAccessToken) {
        const sheetId = selectedFile?.split("/")[5];
        response = await axios.get(
          `https://sheets.googleapis.com/v4/spreadsheets/${sheetId}?key=${process.env.REACT_APP_GOOGLE_API_KEY}`
        );
      } else {
        response = await axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${selectedFile?.id}`, {
          headers: {
            Authorization: `Bearer ${googleAccessToken}`,
          },
        });
      }

      const sheets =
        response?.data?.sheets &&
        response.data.sheets.map((sheet) => ({
          tableName: sheet.properties.title,
          schemaName: null,
          labelName: sheet.properties.title,
          included: true,
          show: true,
        }));

      setTables(sheets);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.log(e);
    }
  };

  const doCreateNewGoogleSheet = (data, e) => {
    e.preventDefault();

    setDatasource(data);
    setAlerts([]);

    const googleSheetDatasource = {
      ...data,
      isLiveConnection,
      tables: [],
      settings: {
        includeAllTables: includeAll,
      },
    };

    getSheetNames(data.sheetUrl);
    Actions.createNew({ datasource: googleSheetDatasource });
  };

  const doStartCsvUpload = (data, e) => {
    e.preventDefault();
    setAlerts([]);

    data.files = files;

    if (organizationsByRoleOptions?.length && !data.organization) {
      data.organization = organizationsByRoleOptions[0].value;
    }

    setDatasource(data);

    const formData = new FormData();

    // Update the formData object
    files.forEach((file) => formData.append("files", file));

    if (datasourceIdForCsv) {
      formData.append("newDS", false);
      formData.append("datasource", datasourceIdForCsv);
    } else {
      formData.append("newDS", true);
      formData.append("organization", data.organization);
      formData.append("databasename", data.csvdatabasename);
    }

    const org = accountData?.billingPlans.find((plan) => plan.organizationId === data.organization);
    const tier = org?.tier;

    if (!accountData || !tier || tier === PricingTiers.BASIC) {
      QueryManagerActions.startCsvUpload(formData);
    } else {
      QueryManagerActions.startLargeCsvUpload(formData);
    }
  };

  const onClickNextCsvImportCreate = () => {
    const toImport = Object.entries(tableNamesForCsvImport)?.map((file) => {
      const fileId = file[0];
      const isFileIncluded = filesToImport.filter((f) => f.fileId === fileId && f.included)?.length;

      const tableName = file[1]?.endsWith(".csv") ? file[1]?.split(".csv")[0] : file[1];

      if (isFileIncluded) {
        return {
          fileId,
          tableName: tableName,
          mode: "create",
        };
      }
    });

    const payload = {
      csvUploadId: dbImporterValue?._id,
      files: toImport.filter(Boolean),
    };

    QueryManagerActions.startCsvImport(payload);
  };

  const updateTables = () => {
    Actions.updateTables(createdDatasource._id, {
      settings: {
        includeAllTables: includeAll,
      },
      tables: tables.map((table) => ({
        tableName: table?.tableName,
        schemaName: table.schemaName,
        labelName: table.labelName,
        included: table.included,
        columnSchema: table.columnSchema,
      })),
    });
  };

  const updateTableCsvImport = () => {
    setDatasourceIdForCsv(dbImporterValue._id);
    QueryManagerActions.refreshTables({ datasource: dbImporterValue._id });
  };

  const uploadCorrectedCsvFile = () => {
    setStep(0);
    setFiles();
    setDatasourceIdForCsv(dbImporterValue._id);
  };

  const handleInclude = (e, index) => {
    const table = tables[index];

    table.included = e.target.checked;

    const _tables = [...tables];

    if (!e.target.checked) {
      setIncludeAll(false);
    }

    setTables(_tables);
  };

  const handleIncludeAll = (e) => {
    setIncludeAll(e.target.checked);

    for (const table of tables) {
      table.included = e.target.checked;
    }
    setTables([...tables]);
  };

  const next = () => {
    setStep(++step);
  };

  const previous = () => {
    setStep(--step);
  };

  const close = () => {
    setSteps(datasourceRegularSteps);
    setAlerts([]);
    setDatasourceType("");
    setType("");
    setStep(0);
    setTables([]);
    setIncludeAll(false);
    handleClose();
    setHasErrorGoogleSheet(false);
    setGoogleAccessToken(null);
    setShowGoogleDriveFilesModal(false);
    setIsLiveConnection(false);
    setSSLWithCertsDiv(false);
    setFiles();
    setDatasourceIdForCsv();
    setTableNamesForCsvImport();
    setHasFileLimitError(false);
    setFilesToSave();
    setFilesToImport();
    setDatasource();

    QueryManagerActions.clearImportState();
    LoginActions.clearTokens();
  };

  const handleHostChange = (e) => {
    if (type === datasourceTypes.CLICKHOUSE) {
      if (e.target.value.includes("https://")) {
        setSSLWithCertsDiv(true);
        setValue("port", 8443, { shouldValidate: true });
        setValue("sslwithcerts", true);
      } else if (e.target.value.includes("http://")) {
        setSSLWithCertsDiv(false);
        setValue("port", 8123, { shouldValidate: true });
        setValue("sslwithcerts", false);
      }
    }

    if (e.target.value && (e.target.value.includes("localhost") || e.target.value.startsWith("192."))) {
      setHostWarning(
        "It looks like your host name is a local IP address not accessible to Zing. Zing only supports internet-accessible IP addresses."
      );
    } else {
      setHostWarning();
    }
  };

  const [sshTunnelDiv, setSSHTunnelDiv] = useState(null);
  const handleShowHideSSH = () => setSSHTunnelDiv(!sshTunnelDiv);

  const [sslWithCertsDiv, setSSLWithCertsDiv] = useState(null);
  const handleShowHideSSLWithCerts = () => setSSLWithCertsDiv(!sslWithCertsDiv);

  const [encrypt, setEncrypt] = useState(true);
  const handleEncrypt = () => setEncrypt(!encrypt);

  useEffect(() => {
    if (timeoutError || (queryResult !== null && queryResult !== undefined && !Array.isArray(queryResult))) {
      if (type === datasourceTypes.GOOGLE_SHEETS && !isLiveConnection) {
        setHasErrorGoogleSheet(true);
      }

      const newAlerts = alerts.concat(
        <Alert
          key="danger"
          type="danger"
          message="Connection to database failed, please check your connection settings."
          details={connectionErr}
        />
      );

      setAlerts(newAlerts);
      instance.logEvent("DATABASE_CONNECTION_ERROR", {
        jobId: jobId,
        jobState: jobState,
        error: connectionErr,
      });

      intercomTrackEvent("database-connection-failed", {
        type,
        ...(type === datasourceTypes.GOOGLE_SHEETS ? isLiveConnection : ""),
      });
    } else if (jobState === "completed") {
      setHasErrorGoogleSheet(false);

      const _tables = queryResult?.map((c) => {
        return {
          tableName: c.table_name,
          schemaName: c.schema_name,
          labelName: c.label_name,
          included: true,
          show: true,
          columnSchema: c.column_schema,
        };
      });

      setTables(_tables);

      instance.logEvent("DATABASE_CONNECTION_SUCCESS", {
        jobId: jobId,
        jobState: jobState,
      });

      if (type === datasourceTypes.GOOGLE_SHEETS && !isLiveConnection) {
        updateTables();
      } else {
        next();
      }
    }
  }, [jobState, queryResult, timeoutError, isLiveConnection]);

  // google sheets - csv injestion
  useEffect(() => {
    if (timeoutErrorIngestion) {
      setHasErrorGoogleSheet(true);

      setAlerts([<Alert key="danger" type="danger" message={timeoutErrorIngestion} />]);

      instance.logEvent("GOOGLE_SHEETS_INJESTION_ERROR", {
        jobId: jobId,
        jobState: jobStateIngestion,
      });

      intercomTrackEvent("database-connection-failed", {
        type,
        ...(type === datasourceTypes.GOOGLE_SHEETS ? isLiveConnection : ""),
      });
    } else if (jobStateIngestion === "completed") {
      setHasErrorGoogleSheet(false);

      QueryManagerActions.startDbImporter({
        datasource: createdDatasource._id,
        filename: ingestionValue,
      });
    }
  }, [jobStateIngestion, timeoutErrorIngestion, ingestionValue]);

  useEffect(() => {
    if (timeoutErrorDbImporter) {
      setHasErrorGoogleSheet(true);

      let message;
      if (type === datasourceTypes.UPLOADED_CSV && csvAction === "upload") {
        message = (
          <span>
            Error importing spreadsheet. Please verify the sheet is correctly{" "}
            <a
              href="https://docs.getzingdata.com/docs/setting-up-a-data-source/excel_csv/"
              target="__blank"
              style={{ color: "#2d2b37", textDecorationLine: "underline" }}
            >
              formatted
            </a>{" "}
            with correct header row and retry.
          </span>
        );

        instance.logEvent("CSV_UPLOAD_ERROR", {
          jobId: jobId,
          jobState: jobStateDbImporter,
          csvAction,
        });
      } else if (type === datasourceTypes.GOOGLE_SHEETS) {
        message = "Error importing the database, please check the google sheet data and try to import again.";

        instance.logEvent("GOOGLE_SHEETS_IMPORT_ERROR", {
          jobId: jobId,
          jobState: jobStateDbImporter,
        });
      }

      if (message) {
        setAlerts([<Alert key="danger" type="danger" message={message} />]);
      }

      intercomTrackEvent("database-connection-failed", {
        type,
        ...(type === datasourceTypes.GOOGLE_SHEETS ? isLiveConnection : ""),
      });
    } else if (jobStateDbImporter === "completed") {
      if (type === datasourceTypes.UPLOADED_CSV && csvAction === "upload") {
        instance.logEvent("CSV_UPLOAD_SUCCESS", {
          jobId: jobId,
          jobState: jobStateDbImporter,
          csvAction,
        });

        setStep(1);
      } else if (type === datasourceTypes.GOOGLE_SHEETS) {
        setHasErrorGoogleSheet(false);

        instance.logEvent("GOOGLE_SHEETS_IMPORT_SUCCESS", {
          jobId: jobId,
          jobState: jobStateDbImporter,
        });

        QueryManagerActions.checkConnection({
          datasource: {
            ...dbImporterValue,
            type,
            displayName,
            datasourceId: createdDatasource._id,
          },
          version: 2,
        });
      }
    }
  }, [jobStateDbImporter, timeoutErrorDbImporter, dbImporterValue, type, csvAction, displayName]);

  useEffect(() => {
    if (type === datasourceTypes.UPLOADED_CSV && csvAction === "import") {
      if (timeoutErrorDbImporter) {
        instance.logEvent("CSV_IMPORT_ERROR", {
          jobId: jobId,
          jobState: jobStateDbImporter,
        });

        intercomTrackEvent("database-connection-failed", {
          type,
          csvAction,
        });
      } else if (jobStateDbImporter === "completed") {
        instance.logEvent("CSV_IMPORT_SUCCESS", {
          jobId: jobId,
          jobState: jobStateDbImporter,
          csvAction,
        });

        setStep(2);
      }
    }
  }, [jobStateDbImporter, timeoutErrorDbImporter, jobId, type, csvAction]);

  useEffect(() => {
    if (jobId && queueType) {
      if (queueType === queueNames.QUERYWORKER) {
        QueryManagerActions.getQueryById(jobId);
      } else if (queueType === queueNames.INGESTION) {
        QueryManagerActions.getQueryIngestorById(jobId);
      } else if (queueType === queueNames.DB_IMPORTER) {
        QueryManagerActions.getQueryDbImporterById(jobId);
      }
    }
  }, [jobId, queueType]);

  useEffect(() => {
    if (showSaveSuccess || showSaveTablesSuccess) {
      next();
      setTables([]);
    }
  }, [showSaveSuccess, showSaveTablesSuccess]);

  useEffect(() => {
    setDatasource(createdDatasource);
    if (type === datasourceTypes.GOOGLE_SHEETS && createdDatasource && createdDatasource._id && !isLiveConnection) {
      doImportGoogleSheet();
    }
  }, [createdDatasource]);

  useEffect(() => {
    if (error) {
      const newAlerts = alerts.concat(<Alert key="danger" type="danger" message={error} />);

      setAlerts(newAlerts);
    }
  }, [error]);

  useEffect(() => {
    if (show) {
      Actions.getFirstDataSource();
      setIncludeAll(true);
      setStep(0);
      setSteps(datasourceRegularSteps);
    }
  }, [show]);

  useEffect(() => {
    if (datasourceType) {
      setDatasourceType(datasourceType);
    }
  }, [datasourceType, setDatasourceType]);

  useEffect(() => {
    if (authType) {
      setAuthTypeValue(authType);
      setValue("authType", authType, { shouldValidate: true });
    }
  }, []);

  const liveConnectionTooltip = (
    <Tooltip id="tooltip">
      Instead of importing the current version of your google sheet, establish a live connection with the sheets you
      choose. This feature is free while in beta but will be on the paid plan after beta.
    </Tooltip>
  );

  const encryptTooltip = (
    <Tooltip id="encrypt-tooltip">If you are using SQL Server on Windows on Azure, turn this on.</Tooltip>
  );

  const handleToApp = useCallback(() => {
    instance.logEvent("START_QUERYING_TAPPED");
  }, []);

  const onClickPreviousOnProFeature = () => {
    setHasFileLimitError(false);
  };

  const checkTableNames = () => {
    const toImport = filesToImport.filter((f) => f.included);
    if (tableNamesForCsvImport && toImport?.length !== Object.values(tableNamesForCsvImport)?.length) {
      return true;
    }

    return false;
  };

  const checkErrorsInFilesToSave = useCallback(() => {
    const filesWithErrors = filesToSave?.filter((f) => f.error && f.included);
    if (filesWithErrors?.length) return true;
    return false;
  }, [filesToSave]);

  const handleDisplayNameChange = (e) => {
    setDisplayName(e.target.value);
  };

  return (
    <>
      {showGoogleDriveFilesModal && (
        <ModalListGoogleDriveFiles
          show={showGoogleDriveFilesModal}
          onHide={handleCloseGoogleDriveModal}
          accessToken={googleAccessToken}
          onSelect={handleSelectGoogleDriveFile}
          getSheetNames={getSheetNames}
        />
      )}
      <Modal size="xl" backdrop="static" header="Create new" keyboard={false} show={show} onHide={close} centered>
        <div className="modal-header">
          <h5 className="modal-title" id="modalDataSourceTitle">
            Add Data Source
          </h5>
          <button type="button" className="btn btn-icon btn-sm btn-ghost-secondary" onClick={close} aria-label="Close">
            <i className="tio-clear tio-lg"></i>
          </button>
        </div>

        <>
          <div className="modal-body">
            <form className="js-step-form">
              <ul className="js-step-progress step step-sm step-icon-sm step-inline step-item-between mb-5">
                {steps.map((el, i) => (
                  <li key={i} className={`step-item ${step === el.step ? " active" : ""}`}>
                    <a className="step-content-wrapper">
                      <span className="step-icon step-icon-soft-dark">{el.step + 1}</span>
                      <div className="step-content">
                        <span className="step-title">{el.title}</span>
                      </div>
                    </a>
                  </li>
                ))}
              </ul>

              {(loading || loadingQuery || refreshingTables) && (
                <Loading loading={loading || loadingQuery || refreshingTables} />
              )}

              {(loadingCsvUpload || (loadingCsvJob && csvAction === "upload")) && (
                <Loading
                  loading={loadingCsvUpload || (loadingCsvJob && csvAction === "upload")}
                  text="Importing Files"
                  secondaryText="This could take a moment..."
                  showLoadingText={false}
                  opacity={1}
                />
              )}

              {(loadingCsvImport || (loadingCsvJob && csvAction === "import")) && (
                <Loading
                  loading={(loadingCsvJob && csvAction === "import") || loadingCsvImport}
                  text="Completing Import"
                  secondaryText="This could take a moment..."
                  showLoadingText={false}
                  opacity={1}
                />
              )}

              {alerts}

              <div id="createDataSourceStepFormContent" style={step === 0 ? {} : { display: "none" }}>
                <div className="row">
                  <div className="col-sm-4">
                    <div className="form-group">
                      <label htmlFor="projectNameNewProjectLabel" className="input-label">
                        Select Data Source
                      </label>
                      <Controller
                        name="type"
                        control={control}
                        render={({ value, ref }) => (
                          <Select
                            styles={{
                              menu: (provided) => ({ ...provided, zIndex: 9999 }),
                            }}
                            className={errors.type ? " is-invalid" : ""}
                            inputRef={ref}
                            options={datasources}
                            isClearable
                            value={datasources.find((c) => c.value === value)}
                            onChange={handleChange}
                            maxMenuHeight={450}
                            autoComplete="off"
                          />
                        )}
                      />
                      <span className="invalid-feedback">{errors.type?.message}</span>
                    </div>
                  </div>

                  {organizationsByRole?.length > 0 && (
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="dataSourceNewOrganizationLabel" className="input-label">
                          Select Organization
                        </label>
                        <Controller
                          name="organization"
                          control={control}
                          render={({ value, ref }) => (
                            <Select
                              styles={{
                                menu: (provided) => ({ ...provided, zIndex: 9999 }),
                              }}
                              className={errors.organization ? " is-invalid" : ""}
                              inputRef={ref}
                              options={organizationsByRoleOptions}
                              isClearable={organizationsByRoleOptions?.length > 1}
                              isDisabled={organizationsByRoleOptions?.length === 1 || files?.length}
                              value={organizationsByRoleOptions.find((c) => c.value === value)}
                              defaultValue={organizationsByRoleOptions[0]}
                              onChange={handleOrganizationChange}
                            />
                          )}
                        />
                        <span className="invalid-feedback">{errors.organization?.message}</span>
                      </div>
                    </div>
                  )}
                  {type !== datasourceTypes.UPLOADED_CSV && (
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="dataSourceNewOrganizationLabel" className="input-label">
                          {type === datasourceTypes.TRINO ? "Display Name (Required)" : "Display Name"}
                        </label>
                        <div
                          className={"input-group input-group-merge" + (errors.displayName ? " is-invalid" : "")}
                          style={{ border: "none" }}
                        >
                          <input
                            type="text"
                            className={"form-control " + (errors.displayName ? " is-invalid" : "")}
                            placeholder="Enter a display name..."
                            name="displayName"
                            aria-label="Enter a display name..."
                            ref={register}
                            style={{ height: "2.35rem", border: "0.0625rem solid hsl(0, 0%, 80%)" }}
                            autoComplete="off"
                            onChange={handleDisplayNameChange}
                          />
                        </div>
                        <span className="invalid-feedback">{errors.displayName?.message}</span>
                      </div>
                    </div>
                  )}
                  {type === datasourceTypes.UPLOADED_CSV && (
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="dataSourceNewOrganizationLabel" className="input-label">
                          Name
                        </label>
                        <div
                          className={"input-group input-group-merge" + (errors.csvdatabasename ? " is-invalid" : "")}
                          style={{ border: "none" }}
                        >
                          <input
                            type="text"
                            className={"form-control " + (errors.csvdatabasename ? " is-invalid" : "")}
                            placeholder="Enter a name..."
                            name="csvdatabasename"
                            aria-label="Enter a name..."
                            ref={register}
                            defaultValue={`db-${Date.now()}`}
                            style={{ height: "2.35rem", border: "0.0625rem solid hsl(0, 0%, 80%)" }}
                            autoComplete="off"
                          />
                        </div>
                        <span className="invalid-feedback">{errors.csvdatabasename?.message}</span>
                      </div>
                    </div>
                  )}
                </div>

                {type === datasourceTypes.GOOGLE_SHEETS && (
                  <div className="row">
                    <div className="col-sm-12">
                      <label className="row form-group toggle-switch" htmlFor="isLiveConnectionRadio">
                        <span className="d-flex align-items-center col-12 col-md-6 col-lg-3">
                          <span className="d-block text-dark">Live Connection</span>
                          <OverlayTrigger placement="bottom" overlay={liveConnectionTooltip}>
                            <i className="tio-help-outlined text-body ml-1" />
                          </OverlayTrigger>
                          <input
                            type="checkbox"
                            name="isLiveConnectionRadio"
                            className="toggle-switch-input"
                            checked={isLiveConnection}
                            onChange={toggleIsLiveConnection}
                            id="isLiveConnectionRadio"
                          />
                          <span className="toggle-switch-label ml-3">
                            <span className="toggle-switch-indicator"></span>
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                )}

                {type !== datasourceTypes.GOOGLE_BIGQUERY &&
                  type !== datasourceTypes.GOOGLE_SHEETS &&
                  type !== datasourceTypes.UPLOADED_CSV && (
                    <div>
                      <div className="row">
                        <div className={type === datasourceTypes.MICROSOFT_SQL ? "col-sm-12" : "col-sm-9"}>
                          <div className="form-group">
                            <label htmlFor="dataSourceNewHostLabel" className="input-label">
                              Host
                            </label>
                            <div className={"input-group input-group-merge" + (errors.host ? " is-invalid" : "")}>
                              <div className="input-group-prepend">
                                <div className="input-group-text">
                                  <i className="tio-briefcase-outlined" />
                                </div>
                              </div>
                              <input
                                type="text"
                                className={"form-control " + (errors.host ? " is-invalid" : "")}
                                name="host"
                                id="dataSourceNewHostLabel"
                                placeholder={(function () {
                                  switch (type) {
                                    case datasourceTypes.SNOWFLAKE:
                                      return "xxx.us-central.gcp";
                                    case datasourceTypes.CLICKHOUSE:
                                      return "clickhouse.server.com or 127.0.0.1";
                                    default:
                                      return "127.0.0.1";
                                  }
                                })()}
                                aria-label="Enter host name here"
                                ref={register}
                                onChange={handleHostChange}
                              />
                            </div>
                            <p className="mt-1">
                              <em>Make sure Zing Data&apos;s IP is in your firewall&apos;s allowlist: 34.75.82.6</em>
                            </p>
                            <span className="invalid-feedback">{errors.host?.message}</span>
                            {hostWarning && (
                              <span className="error-message">
                                <b>{hostWarning}</b>
                              </span>
                            )}
                          </div>
                        </div>
                        <div
                          className="col-sm-3"
                          style={
                            type !== datasourceTypes.SNOWFLAKE && type !== datasourceTypes.MICROSOFT_SQL
                              ? {}
                              : { display: "none" }
                          }
                        >
                          <div className="form-group">
                            <label htmlFor="dataSourcePortLabel" className="input-label">
                              Port
                            </label>
                            <div className="input-group input-group-merge">
                              <div className="input-group-prepend">
                                <div className="input-group-text">
                                  <i className="tio-monitor" />
                                </div>
                              </div>
                              <input
                                type="text"
                                className={"form-control " + (errors.port ? " is-invalid" : "")}
                                name="port"
                                id="dataSourcePortLabel"
                                placeholder={(function () {
                                  switch (type) {
                                    case datasourceTypes.POSTGRES:
                                      return "5432";
                                    case datasourceTypes.MYSQL:
                                      return "3306";
                                    case datasourceTypes.CLICKHOUSE:
                                      return "8123";
                                    default:
                                      return "127.0.0.1";
                                  }
                                })()}
                                aria-label="Enter port number here"
                                ref={register}
                              />
                              <span className="invalid-feedback">{errors.port?.message}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        {type !== datasourceTypes.TRINO && (
                          <div
                            className={
                              type === datasourceTypes.DATABRICKS || type === datasourceTypes.POSTGRES
                                ? "col-sm-6"
                                : type === datasourceTypes.MICROSOFT_SQL
                                ? "col-sm-12"
                                : "col-sm-4"
                            }
                          >
                            <div className="form-group">
                              <label htmlFor="dataSourceDatabaseLabel" className="input-label">
                                {type === datasourceTypes.DATABRICKS ? "Schema" : "Database Name"}
                              </label>
                              <div
                                className={"input-group input-group-merge" + (errors.databasename ? " is-invalid" : "")}
                              >
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-documents" />
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className={"form-control " + (errors.databasename ? " is-invalid" : "")}
                                  id="dataSourceDatabaseLabel"
                                  placeholder="users"
                                  name="databasename"
                                  aria-label={
                                    type !== datasourceTypes.DATABRICKS
                                      ? "Enter database name here"
                                      : "Enter the schema name here"
                                  }
                                  ref={register}
                                />
                              </div>
                              <span className="invalid-feedback">{errors.databasename?.message}</span>
                            </div>
                          </div>
                        )}

                        {(type === datasourceTypes.SNOWFLAKE || type === datasourceTypes.POSTGRES) && (
                          <div className={type === datasourceTypes.POSTGRES ? "col-sm-6" : "col-sm-4"}>
                            <div className="form-group">
                              <label htmlFor="dataSourceSchemaLabel" className="input-label">
                                Schema Name
                              </label>
                              <div className="input-group input-group-merge">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-documents" />
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className={"form-control " + (errors.schemaname ? " is-invalid" : "")}
                                  disabled={type === datasourceTypes.SNOWFLAKE}
                                  id="dataSourceSchemaLabel"
                                  placeholder={type === datasourceTypes.SNOWFLAKE ? "coming soon" : "public"}
                                  name="schemaname"
                                  aria-label="Enter schema name here"
                                  ref={register}
                                />
                              </div>
                              <span className="invalid-feedback">{errors.schemaname?.message}</span>
                            </div>
                          </div>
                        )}

                        {type === datasourceTypes.SNOWFLAKE && (
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="dataSourceWarehouseLabel" className="input-label">
                                Warehouse Name
                              </label>
                              <div className="input-group input-group-merge">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-documents" />
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className={"form-control " + (errors.warehousename ? " is-invalid" : "")}
                                  id="dataSourceSchemaLabel"
                                  placeholder=""
                                  name="warehousename"
                                  aria-label="Enter Data Warehouse name here"
                                  ref={register}
                                />
                                <span className="invalid-feedback">{errors.warehousename?.message}</span>
                              </div>
                            </div>
                          </div>
                        )}

                        {type === datasourceTypes.MICROSOFT_SQL && (
                          <div className="col-12">
                            <div className="col-sm-2">
                              <label className="row form-group toggle-switch" htmlFor="encrypt">
                                <span className="col-sm-9 toggle-switch-content ml-0 pl-0">
                                  <span className="d-block text-dark">
                                    Encrypt
                                    <OverlayTrigger placement="right" overlay={encryptTooltip}>
                                      <i className="tio-help-outlined text-body ml-1" />
                                    </OverlayTrigger>
                                  </span>
                                </span>
                                <span className="col-sm-2">
                                  <input
                                    type="checkbox"
                                    name="encrypt"
                                    className="toggle-switch-input"
                                    id="encrypt"
                                    onChange={handleEncrypt}
                                    ref={register}
                                    checked={encrypt}
                                  />
                                  <span className="toggle-switch-label ml-auto">
                                    <span className="toggle-switch-indicator"></span>
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        )}

                        {(type === datasourceTypes.DATABRICKS ||
                          (type === datasourceTypes.MICROSOFT_SQL &&
                            authType === "azure-active-directory-access-token")) && (
                          <div className={type === datasourceTypes.MICROSOFT_SQL ? "col-sm-12" : "col-sm-6"}>
                            <div className="form-group">
                              <label htmlFor="dataSourceNewTokenLabel" className="input-label">
                                Token
                              </label>
                              <div className={"input-group input-group-merge" + (errors.token ? " is-invalid" : "")}>
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-lock" />
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className={"form-control " + (errors.token ? " is-invalid" : "")}
                                  name="token"
                                  id="dataSourceNewTokenLabel"
                                  placeholder="token"
                                  aria-label="Enter the token here"
                                  ref={register}
                                />
                              </div>
                              <span className="invalid-feedback">{errors.token?.message}</span>
                            </div>
                          </div>
                        )}

                        {((type !== datasourceTypes.DATABRICKS && type !== datasourceTypes.MICROSOFT_SQL) ||
                          (type === datasourceTypes.MICROSOFT_SQL &&
                            (authType === msSqlAuthTypes[0].value ||
                              authType === "azure-active-directory-password" ||
                              authType === "ntlm"))) && (
                          <>
                            <div
                              className={
                                type === datasourceTypes.MICROSOFT_SQL ||
                                type === datasourceTypes.POSTGRES ||
                                type === datasourceTypes.TRINO
                                  ? "col-sm-6"
                                  : "col-sm-4"
                              }
                            >
                              <div className="form-group">
                                <label htmlFor="dataSourceUsernameLabel" className="input-label">
                                  Username
                                </label>
                                <div className="input-group input-group-merge">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-user" />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className={"form-control " + (errors.username ? " is-invalid" : "")}
                                    name="username"
                                    id="dataSourceUsernameLabel"
                                    placeholder="admin"
                                    autoComplete="off"
                                    ref={register}
                                  />
                                  <span className="invalid-feedback">{errors.username?.message}</span>
                                </div>
                              </div>
                            </div>

                            <div
                              className={
                                type === datasourceTypes.MICROSOFT_SQL ||
                                type === datasourceTypes.POSTGRES ||
                                type === datasourceTypes.TRINO
                                  ? "col-sm-6"
                                  : "col-sm-4"
                              }
                            >
                              <div className="form-group">
                                <label htmlFor="dataSourcePasswordLabel" className="input-label">
                                  Password
                                </label>
                                <div className="input-group input-group-merge">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-lock" />
                                    </div>
                                  </div>
                                  <input
                                    type="password"
                                    className={"form-control " + (errors.password ? " is-invalid" : "")}
                                    name="password"
                                    id="dataSourcePasswordLabel"
                                    placeholder="password"
                                    autoComplete="off"
                                    ref={register}
                                  />
                                </div>
                                <span
                                  className="invalid-feedback"
                                  style={{ display: errors.password ? "block" : "none" }}
                                >
                                  {errors.password?.message}
                                </span>
                              </div>
                            </div>
                          </>
                        )}

                        {type === datasourceTypes.MICROSOFT_SQL && (
                          <>
                            {(authType === "azure-active-directory-msi-app-service" ||
                              authType === "azure-active-directory-msi-vm" ||
                              authType === "azure-active-directory-service-principal-secret") && (
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label htmlFor="dataSourceClientIdLabel" className="input-label">
                                    Client ID
                                  </label>
                                  <div
                                    className={"input-group input-group-merge" + (errors.clientId ? " is-invalid" : "")}
                                  >
                                    <div className="input-group-prepend">
                                      <div className="input-group-text">
                                        <i className="tio-briefcase-outlined" />
                                      </div>
                                    </div>
                                    <input
                                      type="text"
                                      className={"form-control " + (errors.clientId ? " is-invalid" : "")}
                                      name="clientId"
                                      id="dataSourceClientIdLabel"
                                      placeholder="Client Id"
                                      aria-label="Enter your client id here"
                                      ref={register}
                                    />
                                  </div>
                                  <span className="invalid-feedback">{errors.projectId?.message}</span>
                                </div>
                              </div>
                            )}
                            {(authType === "azure-active-directory-msi-app-service" ||
                              authType === "azure-active-directory-msi-vm") && (
                              <div className={authType === "azure-active-directory-msi-vm" ? "col-sm-12" : "col-sm-6"}>
                                <div className="form-group">
                                  <label htmlFor="dataSourceMsiEndpointLabel" className="input-label">
                                    MSI Endpoint
                                  </label>
                                  <div
                                    className={
                                      "input-group input-group-merge" + (errors.msiEndpoint ? " is-invalid" : "")
                                    }
                                  >
                                    <div className="input-group-prepend">
                                      <div className="input-group-text">
                                        <i className="tio-briefcase-outlined" />
                                      </div>
                                    </div>
                                    <input
                                      type="text"
                                      className={"form-control " + (errors.msiEndpoint ? " is-invalid" : "")}
                                      name="msiEndpoint"
                                      id="dataSourceMsiEndpointLabel"
                                      placeholder="MSI Endpoint"
                                      aria-label="Enter your MSI Endpoint here"
                                      ref={register}
                                    />
                                  </div>
                                  <span className="invalid-feedback">{errors.msiEndpoint?.message}</span>
                                </div>
                              </div>
                            )}
                            {authType === "azure-active-directory-msi-app-service" && (
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label htmlFor="dataSourceMsiSecretLabel" className="input-label">
                                    MSI Secret
                                  </label>
                                  <div
                                    className={
                                      "input-group input-group-merge" + (errors.msiSecret ? " is-invalid" : "")
                                    }
                                  >
                                    <div className="input-group-prepend">
                                      <div className="input-group-text">
                                        <i className="tio-briefcase-outlined" />
                                      </div>
                                    </div>
                                    <input
                                      type="text"
                                      className={"form-control " + (errors.msiSecret ? " is-invalid" : "")}
                                      name="msiSecret"
                                      id="dataSourceMsiSecretLabel"
                                      placeholder="MSI Secret"
                                      aria-label="Enter your MSI secret here"
                                      ref={register}
                                    />
                                  </div>
                                  <span className="invalid-feedback">{errors.msiSecret?.message}</span>
                                </div>
                              </div>
                            )}
                            {(authType === "azure-active-directory-password" || authType === "ntlm") && (
                              <div className="col-sm-12">
                                <div className="form-group">
                                  <label htmlFor="dataSourceDomainLabel" className="input-label">
                                    Domain
                                  </label>
                                  <div
                                    className={"input-group input-group-merge" + (errors.domain ? " is-invalid" : "")}
                                  >
                                    <div className="input-group-prepend">
                                      <div className="input-group-text">
                                        <i className="tio-briefcase-outlined" />
                                      </div>
                                    </div>
                                    <input
                                      type="text"
                                      className={"form-control " + (errors.domain ? " is-invalid" : "")}
                                      name="domain"
                                      id="dataSourceDomainLabel"
                                      placeholder="domain"
                                      aria-label="Enter your domain here"
                                      ref={register}
                                    />
                                  </div>
                                  <span className="invalid-feedback">{errors.domain?.message}</span>
                                </div>
                              </div>
                            )}
                            {authType === "azure-active-directory-service-principal-secret" && (
                              <>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label htmlFor="dataSourceClientSecretLabel" className="input-label">
                                      Client Secret
                                    </label>
                                    <div
                                      className={
                                        "input-group input-group-merge" + (errors.clientSecret ? " is-invalid" : "")
                                      }
                                    >
                                      <div className="input-group-prepend">
                                        <div className="input-group-text">
                                          <i className="tio-briefcase-outlined" />
                                        </div>
                                      </div>
                                      <input
                                        type="text"
                                        className={"form-control " + (errors.clientSecret ? " is-invalid" : "")}
                                        name="clientSecret"
                                        id="dataSourceClientSecretLabel"
                                        placeholder="Client Secret"
                                        aria-label="Enter your client secret here"
                                        ref={register}
                                      />
                                    </div>
                                    <span className="invalid-feedback">{errors.clientSecret?.message}</span>
                                  </div>
                                </div>
                                <div className="col-sm-6">
                                  <div className="form-group">
                                    <label htmlFor="dataSourceTenantIdLabel" className="input-label">
                                      Tenant ID
                                    </label>
                                    <div
                                      className={
                                        "input-group input-group-merge" + (errors.tenantId ? " is-invalid" : "")
                                      }
                                    >
                                      <div className="input-group-prepend">
                                        <div className="input-group-text">
                                          <i className="tio-briefcase-outlined" />
                                        </div>
                                      </div>
                                      <input
                                        type="text"
                                        className={"form-control " + (errors.tenantId ? " is-invalid" : "")}
                                        name="tenantId"
                                        id="dataSourceTenantIdLabel"
                                        placeholder="Tenant ID"
                                        aria-label="Enter your tenant Id here"
                                        ref={register}
                                      />
                                    </div>
                                    <span className="invalid-feedback">{errors.tenantId?.message}</span>
                                  </div>
                                </div>
                              </>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  )}

                {type === datasourceTypes.GOOGLE_BIGQUERY && (
                  <div>
                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="dataSourceNewProjectIdLabel" className="input-label">
                            Project Id{" "}
                            <i
                              className="tio-help-outlined text-body ml-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Google project id"
                            />
                          </label>
                          <div className={"input-group input-group-merge" + (errors.projectId ? " is-invalid" : "")}>
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="tio-briefcase-outlined" />
                              </div>
                            </div>
                            <input
                              type="text"
                              className={"form-control " + (errors.projectId ? " is-invalid" : "")}
                              name="projectId"
                              id="dataSourceNewProjectIdLabel"
                              placeholder="Project Id"
                              aria-label="Enter your google project id here"
                              ref={register}
                            />
                          </div>
                          <span className="invalid-feedback">{errors.projectId?.message}</span>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="dataSourceNewDatasetIdLabel" className="input-label">
                            Dataset Id{" "}
                            <i
                              className="tio-help-outlined text-body ml-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Google dataset id"
                            />
                          </label>
                          <div className={"input-group input-group-merge" + (errors.datasetId ? " is-invalid" : "")}>
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="tio-briefcase-outlined" />
                              </div>
                            </div>
                            <input
                              type="text"
                              className={"form-control " + (errors.datasetId ? " is-invalid" : "")}
                              name="datasetId"
                              id="dataSourceNewDatasetIdLabel"
                              placeholder="Dataset Id"
                              aria-label="Enter the dataset id here"
                              ref={register}
                            />
                          </div>
                          <span className="invalid-feedback">{errors.datasetId?.message}</span>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="dataSourceNewDatasetIdLabel" className="input-label">
                            Region (Location)
                          </label>
                          <div className={"input-group input-group-merge" + (errors.location ? " is-invalid" : "")}>
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="tio-globe" />
                              </div>
                            </div>
                            <input
                              type="text"
                              className={"form-control " + (errors.location ? " is-invalid" : "")}
                              name="location"
                              placeholder="Enter the region"
                              aria-label="Enter the region"
                              ref={register}
                            />
                          </div>
                          <span className="invalid-feedback">{errors.location?.message}</span>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="dataSourceNewServiceAccountLabel" className="input-label">
                            Service Account{" "}
                            <i
                              className="tio-help-outlined text-body ml-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Copy and paste the entire service account .json file here"
                            />
                          </label>
                          <div
                            className={"input-group input-group-merge" + (errors.serviceAccount ? " is-invalid" : "")}
                          >
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="tio-briefcase-outlined" />
                              </div>
                            </div>
                            <input
                              type="text"
                              className={"form-control " + (errors.serviceAccount ? " is-invalid" : "")}
                              name="serviceAccount"
                              id="dataSourceNewServiceAccountLabel"
                              placeholder="Service Account .json content"
                              aria-label="Copy and paste the entire service account .json file here"
                              ref={register}
                            />
                          </div>
                          <small>Copy and paste the entire service account .json file here</small>
                          <span className="invalid-feedback">{errors.serviceAccount?.message}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {type === datasourceTypes.UPLOADED_CSV && (
                  <div className="row">
                    <div className="col-sm-12">
                      {organization && (!orgTier || orgTier === PricingTiers.BASIC) && hasFileLimitError ? (
                        <ProFeature
                          organization={organization}
                          setHasFileLimitError={setHasFileLimitError}
                          onClickPreviousOnProFeature={onClickPreviousOnProFeature}
                        />
                      ) : (
                        <>
                          <label className="input-label">Add Data</label>
                          <FileDropZone
                            files={files}
                            tier={orgTier}
                            setFiles={setFiles}
                            setHasFileLimitError={setHasFileLimitError}
                          />
                        </>
                      )}
                    </div>
                  </div>
                )}

                <div style={type === datasourceTypes.GOOGLE_SHEETS ? {} : { display: "none" }}>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <a className="btn btn-lg btn-block btn-white mb-4" onClick={accessGoogleSheets}>
                          <span className="d-flex justify-content-center align-items-center">
                            <img
                              className="avatar avatar-xss mr-2"
                              src="./assets/svg/brands/google.svg"
                              alt="Google Sign In"
                            />
                            Access your Google Sheets
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <div className="text-center">
                          <span>or</span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="dataSourceNewSheetUrlLabel" className="input-label">
                          Google Sheet URL
                        </label>
                        <div className={"input-group input-group-merge" + (errors.sheetUrl ? " is-invalid" : "")}>
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="tio-briefcase-outlined" />
                            </div>
                          </div>
                          <input
                            type="text"
                            className={"form-control " + (errors.sheetUrl ? " is-invalid" : "")}
                            name="sheetUrl"
                            id="dataSourceNewSheetUrlLabel"
                            placeholder="Google sheet public URL"
                            aria-label="Google sheet public URL"
                            ref={register}
                          />
                        </div>
                        <small>
                          From sheets tap <strong>Share</strong> then <strong>Copy Link</strong>
                        </small>
                        <span className="invalid-feedback">{errors.sheetUrl?.message}</span>
                        <br></br>
                        <small>
                          <a href="https://docs.getzingdata.com/docs/setting-up-a-data-source/google_sheets_setup/">
                            Guidelines for formatting your Google Sheet
                          </a>
                        </small>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="dataSourceNewTableNameLabel" className="input-label">
                          Table Name{" "}
                          <i
                            className="tio-help-outlined text-body ml-1"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Name of the table will be created on database"
                          />
                        </label>
                        <div className={"input-group input-group-merge" + (errors?.tableName ? " is-invalid" : "")}>
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="tio-briefcase-outlined" />
                            </div>
                          </div>
                          <input
                            type="text"
                            className={"form-control " + (errors?.tableName ? " is-invalid" : "")}
                            name="tableName"
                            id="dataSourceNewTableNameLabel"
                            placeholder="Table name"
                            aria-label="Table name"
                            ref={register}
                          />
                        </div>
                        <span className="invalid-feedback">{errors?.tableName?.message}</span>
                      </div>
                    </div>
                  </div>
                </div>

                {(type === datasourceTypes.TRINO || type === datasourceTypes.PRESTO) && (
                  <>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="dataSourceNewCatalogLabel" className="input-label">
                            Catalog
                          </label>
                          <div className={"input-group input-group-merge" + (errors.catalog ? " is-invalid" : "")}>
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="tio-briefcase-outlined" />
                              </div>
                            </div>
                            <input
                              type="text"
                              className={"form-control " + (errors.catalog ? " is-invalid" : "")}
                              name="catalog"
                              id="dataSourceNewCatalogLabel"
                              placeholder="Catalog"
                              aria-label="Enter the catalog here"
                              ref={register}
                            />
                          </div>
                          <span className="invalid-feedback">{errors.catalog?.message}</span>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group">
                          <label htmlFor="dataSourceNewSchemaLabel" className="input-label">
                            Schema
                          </label>
                          <div className={"input-group input-group-merge" + (errors.schema ? " is-invalid" : "")}>
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="tio-briefcase-outlined" />
                              </div>
                            </div>
                            <input
                              type="text"
                              className={"form-control " + (errors.schema ? " is-invalid" : "")}
                              name="schema"
                              id="dataSourceNewSchemaLabel"
                              placeholder="Schema"
                              aria-label="Enter the schema here"
                              ref={register}
                            />
                          </div>
                          <span className="invalid-feedback">{errors.schema?.message}</span>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        <div className="form-group">
                          <label htmlFor="dataSourceRoleLabel" className="input-label">
                            Role
                          </label>
                          <div className={"input-group input-group-merge" + (errors.role ? " is-invalid" : "")}>
                            <div className="input-group-prepend">
                              <div className="input-group-text">
                                <i className="tio-user" />
                              </div>
                            </div>
                            <input
                              type="text"
                              className={"form-control " + (errors.role ? " is-invalid" : "")}
                              name="role"
                              id="dataSourceRoleLabel"
                              placeholder="Role"
                              aria-label="Enter the role here"
                              ref={register}
                            />
                          </div>
                          <span className="invalid-feedback">{errors.role?.message}</span>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {type === datasourceTypes.SNOWFLAKE && (
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="dataSourceNewPrivateKeySnowflakeLabel" className="input-label">
                          Private Key{" "}
                          <i
                            className="tio-help-outlined text-body ml-1"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Copy and paste your private key file contents here"
                          />
                        </label>
                        <div
                          className={
                            "input-group input-group-merge" + (errors.privatekeysnowflake ? " is-invalid" : "")
                          }
                        >
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="tio-briefcase-outlined" />
                            </div>
                          </div>
                          <textarea
                            className={"form-control " + (errors.privatekeysnowflake ? " is-invalid" : "")}
                            name="privatekeysnowflake"
                            id="dataSourceNewPrivateKeySnowflakeLabel"
                            placeholder="SSH Private Key content"
                            aria-label="Copy and paste your ssh private key file contents here"
                            ref={register}
                          />
                        </div>
                        <small>Copy and paste your private key file contents here</small>
                        <span className="invalid-feedback">{errors.privatekeysnowflake?.message}</span>
                      </div>
                    </div>
                  </div>
                )}

                {type === datasourceTypes.DATABRICKS && (
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="dataSourceNewHttpPathLabel" className="input-label">
                          HTTP Path
                        </label>
                        <div className={"input-group input-group-merge" + (errors.httpPath ? " is-invalid" : "")}>
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="tio-briefcase-outlined" />
                            </div>
                          </div>
                          <input
                            type="text"
                            className={"form-control " + (errors.httpPath ? " is-invalid" : "")}
                            name="httpPath"
                            id="dataSourceNewHttpPathLabel"
                            placeholder="/sql/1.0/endpoints/****************"
                            aria-label="Enter the HTTP path here"
                            ref={register}
                          />
                        </div>
                        <span className="invalid-feedback">{errors.httpPath?.message}</span>
                      </div>
                    </div>
                  </div>
                )}

                {DATASOURCES_WITH_SSL_CUSTOMCERTS.includes(type) && (
                  <div className="row">
                    <div className="col-12 col-sm-12">
                      <label className="row form-group toggle-switch" htmlFor="sslwithcerts">
                        <span className="col-9 col-sm-3 col-md-2 toggle-switch-content ml-0">
                          <span className="d-block text-dark">Require SSL</span>
                        </span>
                        <span className="col-3 col-sm-1 col-md-1">
                          <input
                            type="checkbox"
                            name="sslwithcerts"
                            className="toggle-switch-input"
                            id="sslwithcerts"
                            onChange={handleShowHideSSLWithCerts}
                            ref={register}
                          />
                          <span className="toggle-switch-label ml-auto">
                            <span className="toggle-switch-indicator"></span>
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                )}

                <div
                  style={DATASOURCES_WITH_SSL_CUSTOMCERTS.includes(type) && sslWithCertsDiv ? {} : { display: "none" }}
                >
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="dataSourceNewsslserverrootcertLabel" className="input-label">
                          Server Root Certificate
                        </label>
                        <div
                          className={"input-group input-group-merge" + (errors.sslserverrootcert ? " is-invalid" : "")}
                        >
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="tio-briefcase-outlined" />
                            </div>
                          </div>
                          <textarea
                            className={"form-control " + (errors.sslserverrootcert ? " is-invalid" : "")}
                            name="sslserverrootcert"
                            id="dataSourceNewsslserverrootcertLabel"
                            placeholder="Server Root Certificate"
                            aria-label="Copy and paste your ssh server root certificate file contents here"
                            ref={register}
                          />
                        </div>
                        <small>Copy and paste your SSL Server root certificate file contents here</small>
                        <span className="invalid-feedback">{errors.sslserverrootcert?.message}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="dataSourceNewsslclientkeyLabel" className="input-label">
                          Client Key
                        </label>
                        <div className={"input-group input-group-merge" + (errors.sslclientkey ? " is-invalid" : "")}>
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="tio-briefcase-outlined" />
                            </div>
                          </div>
                          <textarea
                            className={"form-control " + (errors.sslclientkey ? " is-invalid" : "")}
                            name="sslclientkey"
                            id="dataSourceNewsslclientkeyLabel"
                            placeholder="Client Key"
                            aria-label="Copy and paste your ssl client key contents here"
                            ref={register}
                          />
                        </div>
                        <small>Copy and paste your SSL client key file contents here</small>
                        <span className="invalid-feedback">{errors.sslclientkey?.message}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="dataSourceNewsslclientcertLabel" className="input-label">
                          Client Certificate
                        </label>
                        <div className={"input-group input-group-merge" + (errors.sslclientcert ? " is-invalid" : "")}>
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="tio-briefcase-outlined" />
                            </div>
                          </div>
                          <textarea
                            className={"form-control " + (errors.sslclientcert ? " is-invalid" : "")}
                            name="sslclientcert"
                            id="dataSourceNewsslserverclientcertLabel"
                            placeholder="Client Certificate"
                            aria-label="Copy and paste your ssl client certificate contents here"
                            ref={register}
                          />
                        </div>
                        <small>Copy and paste your SSL client certificate file contents here</small>
                        <span className="invalid-feedback">{errors.sslclientcert?.message}</span>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={DATASOURCES_WITH_SSHTUNNEL.includes(type) ? {} : { display: "none" }}>
                  <div className="row">
                    <div className="col-12 col-sm-12">
                      <label className="row form-group toggle-switch" htmlFor="sshtunnel">
                        <span className="col-9 col-sm-3 col-md-2 toggle-switch-content ml-0">
                          <span className="d-block text-dark">Require SSH</span>
                        </span>
                        <span className="col-3 col-sm-3 col-md-1">
                          <input
                            type="checkbox"
                            name="sshtunnel"
                            className="toggle-switch-input"
                            id="sshtunnel"
                            onChange={handleShowHideSSH}
                            ref={register}
                          />
                          <span className="toggle-switch-label ml-auto">
                            <span className="toggle-switch-indicator"></span>
                          </span>
                        </span>
                      </label>
                    </div>
                  </div>
                </div>

                <div style={DATASOURCES_WITH_SSHTUNNEL.includes(type) && sshTunnelDiv ? {} : { display: "none" }}>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group">
                        <label htmlFor="dataSourceNewSSHPrivateKeyLabel" className="input-label">
                          SSH Private Key{" "}
                          <i
                            className="tio-help-outlined text-body ml-1"
                            data-toggle="tooltip"
                            data-placement="top"
                            title="Copy and paste your ssh private key file contents here"
                          />
                        </label>
                        <div className={"input-group input-group-merge" + (errors.sshprivatekey ? " is-invalid" : "")}>
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="tio-briefcase-outlined" />
                            </div>
                          </div>
                          <textarea
                            className={"form-control " + (errors.sshprivatekey ? " is-invalid" : "")}
                            name="sshprivatekey"
                            id="dataSourceNewSSHPrivateKeyLabel"
                            placeholder="SSH Private Key content"
                            aria-label="Copy and paste your ssh private key file contents here"
                            ref={register}
                          />
                        </div>
                        <small>Copy and paste your ssh private key file contents here</small>
                        <span className="invalid-feedback">{errors.sshprivatekey?.message}</span>
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="dataSourceSSHTunnelPortLabel" className="input-label">
                          SSH Port
                        </label>
                        <div className="input-group input-group-merge">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="tio-monitor" />
                            </div>
                          </div>
                          <input
                            type="text"
                            className={"form-control " + (errors.sshtunnelport ? " is-invalid" : "")}
                            name="sshtunnelport"
                            id="dataSourceSSHTunnelPortLabel"
                            placeholder="ssh-port"
                            autoComplete="new-sshtunnelport"
                            ref={register}
                          />
                          <span className="invalid-feedback">{errors.sshtunnelport?.message}</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="dataSourceSSHTunnelUsernameLabel" className="input-label">
                          SSH Username
                        </label>
                        <div className="input-group input-group-merge">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="tio-monitor" />
                            </div>
                          </div>
                          <input
                            type="text"
                            className={"form-control " + (errors.sshtunnelusername ? " is-invalid" : "")}
                            name="sshtunnelusername"
                            id="dataSourceSSHTunnelUsernameLabel"
                            placeholder="ssh-user"
                            autoComplete="new-sshtunnelusername"
                            ref={register}
                          />
                          <span className="invalid-feedback">{errors.sshtunnelusername?.message}</span>
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-4">
                      <div className="form-group">
                        <label htmlFor="dataSourceSSHTunnelPasswordLabel" className="input-label">
                          SSH Password
                        </label>
                        <div className="input-group input-group-merge">
                          <div className="input-group-prepend">
                            <div className="input-group-text">
                              <i className="tio-lock" />
                            </div>
                          </div>
                          <input
                            type="password"
                            className={"form-control " + (errors.sshtunnelpassword ? " is-invalid" : "")}
                            name="sshtunnelpassword"
                            id="dataSourceSSHTunnelPasswordLabel"
                            placeholder="***"
                            autoComplete="new-sshtunnelpassword"
                            ref={register}
                          />
                          <span className="invalid-feedback">{errors.sshtunnelpassword?.message}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {type === datasourceTypes.GOOGLE_SHEETS && !isLiveConnection && step === 1 && (
                <div id="createDataSourceStepImport">
                  <div className="text-center">
                    <div className="mb-4">
                      {hasErrorGoogleSheet ? (
                        <div>
                          <h2>Data not imported</h2>
                          <p>
                            You sheet isn&apos;t formatted correctly and cannot be imported.{" "}
                            <a href="https://docs.getzingdata.com/docs/setting-up-a-data-source/google_sheets_setup/">
                              Click here
                            </a>{" "}
                            to view a guide on how your file should be formatted and retry.
                          </p>
                        </div>
                      ) : (
                        <div>
                          <h2>Importing...</h2>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}

              {step === 1 &&
                ((type === datasourceTypes.GOOGLE_SHEETS && isLiveConnection) ||
                  (type !== datasourceTypes.GOOGLE_SHEETS && type !== datasourceTypes.UPLOADED_CSV)) && (
                  <div id="createDataSourceStepTablesContent">
                    <DataSourceTables
                      tables={tables}
                      includeAll={includeAll}
                      handleIncludeAll={handleIncludeAll}
                      handleInclude={handleInclude}
                    ></DataSourceTables>
                  </div>
                )}

              {step === 1 && type === datasourceTypes.UPLOADED_CSV && filesToImport && (
                <FilesTable
                  step={1}
                  filesToImport={filesToImport}
                  setFilesToImport={setFilesToImport}
                  setTableNames={setTableNamesForCsvImport}
                  tableNames={tableNamesForCsvImport}
                  setHasNameDuplicates={setHasNameDuplicates}
                />
              )}

              {step === 2 && type === datasourceTypes.UPLOADED_CSV && filesToSave && (
                <div id="createDataSourceStepImportCsv">
                  <FilesTable
                    step={2}
                    filesToSave={filesToSave}
                    setFilesToSave={setFilesToSave}
                    uploadCorrectedCsvFile={uploadCorrectedCsvFile}
                    tables={tables}
                    setTables={setTables}
                  />
                </div>
              )}

              {((step === 2 && type !== datasourceTypes.UPLOADED_CSV) ||
                (step === 3 && type === datasourceTypes.UPLOADED_CSV)) && (
                <div id="createDataSourceStepSuccessMessage" className="mt-3">
                  <SuccessMessage
                    first={first}
                    handleToApp={handleToApp}
                    datasourceId={datasourceIdForCsv || datasource?._id}
                  />
                </div>
              )}
            </form>
          </div>
          <Modal.Footer
            className={type === datasourceTypes.UPLOADED_CSV ? "justify-content-between" : "justify-content-end"}
          >
            {step === 0 &&
              type === datasourceTypes.UPLOADED_CSV &&
              (!orgTier || orgTier === PricingTiers.BASIC) &&
              !hasFileLimitError && (
                <div className="mb-2">
                  <i className="tio-info-outlined" style={{ color: "#1D73C9" }}></i> You are on the free plan and
                  limited to one upload at a time. 10MB max each.
                  <Link to={`/organization/${organization}?upgrade=true`}> Upgrade to Pro</Link> to upload multiple
                  files at once.
                </div>
              )}

            {step === 0 && type === datasourceTypes.UPLOADED_CSV && orgTier && orgTier !== PricingTiers.BASIC && (
              <div className="mb-2">
                <i className="tio-info-outlined" style={{ color: "#1D73C9" }}></i> You are on the {orgTier} plan and you
                can upload multiple files at once. 100MB max each.
              </div>
            )}

            <div className="w-100 d-flex justify-content-end">
              <Button variant="default" className="mr-1" onClick={close} disabled={loading || loadingQuery}>
                Close
              </Button>

              {type !== datasourceTypes.GOOGLE_SHEETS && step > 0 && step < 2 && (
                <Button variant="secondary" onClick={previous} className="mr-1">
                  Previous
                </Button>
              )}

              {step === 0 && type !== datasourceTypes.GOOGLE_SHEETS && type !== datasourceTypes.UPLOADED_CSV && (
                <Button
                  variant="primary"
                  type="submit"
                  onClick={handleSubmit(doSave, onError)}
                  disabled={loading || loadingQuery}
                >
                  Check Connection
                </Button>
              )}

              {step === 0 && type === datasourceTypes.GOOGLE_SHEETS && (
                <Button
                  variant="primary"
                  type="submit"
                  onClick={handleSubmit(doCreateNewGoogleSheet, onError)}
                  disabled={loading || loadingQuery}
                >
                  Save
                </Button>
              )}

              {step === 0 && type === datasourceTypes.UPLOADED_CSV && (
                <Button
                  variant="primary"
                  type="submit"
                  onClick={handleSubmit(doStartCsvUpload, onError)}
                  disabled={loading || loadingQuery || !files || hasFileLimitError}
                >
                  Next
                </Button>
              )}

              {step === 1 && type === datasourceTypes.UPLOADED_CSV && (
                <Button
                  variant="primary"
                  type="button"
                  onClick={onClickNextCsvImportCreate}
                  disabled={
                    !tableNamesForCsvImport ||
                    !dbImporterValue?._id ||
                    !filesToImport ||
                    checkTableNames() ||
                    hasNameDuplicates
                  }
                >
                  Next
                </Button>
              )}

              {step === 2 && type === datasourceTypes.UPLOADED_CSV && (
                <Button
                  variant="primary"
                  type="button"
                  onClick={updateTableCsvImport}
                  disabled={loadingCsvUpload || loadingCsvJob || loadingCsvImport || checkErrorsInFilesToSave()}
                >
                  Next
                </Button>
              )}

              {step === 1 && type === datasourceTypes.GOOGLE_SHEETS && !isLiveConnection && (
                <Button
                  variant="primary"
                  type="submit"
                  onClick={doImportGoogleSheet}
                  disabled={loading || loadingQuery}
                >
                  Retry
                </Button>
              )}

              {step === 1 && type !== datasourceTypes.GOOGLE_SHEETS && type !== datasourceTypes.UPLOADED_CSV && (
                <Button variant="primary" type="submit" onClick={doCreateNew} disabled={loading || loadingQuery}>
                  Save
                </Button>
              )}

              {step === 1 && type === datasourceTypes.GOOGLE_SHEETS && isLiveConnection && (
                <Button variant="primary" type="submit" onClick={updateTables} disabled={loading || loadingQuery}>
                  Save
                </Button>
              )}
            </div>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
};

export default ModalDataSource;
