import React, { memo, useState, useCallback, useMemo, useEffect } from "react";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import { useToggle } from "hooks/useToggle";
import EmbedActions from "redux/actions/embed";
import Table from "components/table/table";
import ModalDisableEmbedId from "components/modal/modal-disable-embed-id";
import CopyToClipboard from "components/copy-to-clipboard";

const QuestionEmbedIdsTable = ({ orgId }) => {
  const [refresh, setRefresh] = useState(false);
  const { data, items, pages, loading, toggleEmbedIdSuccess, error } = useSelector((s) => s.embed);
  const [embedIdData, setEmbedIdData] = useState(data);
  const [selectedEmbedIdDataToDisable, setSelectedEmbedIdDataToDisable] = useState();
  const [showDisableEmbedIdModal, toggleDisableEmbedIdModal] = useToggle();

  const fetchData = useCallback(({ pageSize, pageIndex, sort }) => {
    EmbedActions.getQuestionEmbedIdsForOrg(orgId, { limit: pageSize, page: pageIndex + 1, sort });
  }, []);

  useEffect(() => {
    if (!data) {
      fetchData({ pageSize: 20, pageIndex: 0 });
    }
  }, [data]);

  useEffect(() => {
    setEmbedIdData(data);
  }, [data]);

  const refreshData = () => {
    setRefresh(true);
    fetchData({ pageSize: 20, pageIndex: 0 });
  };

  useEffect(() => {
    if (!error && !loading && toggleEmbedIdSuccess) {
      setSelectedEmbedIdDataToDisable();

      if (showDisableEmbedIdModal) {
        toggleDisableEmbedIdModal();
      }

      refreshData();
      EmbedActions.updateEmbedIdToggleSuccess();
    }
  }, [error, loading, toggleEmbedIdSuccess]);

  const toggleEmbedId = (data) => {
    const { _id: embedId, isActive } = data;
    const update = {
      isActive: !isActive,
    };

    EmbedActions.toggleQuestionEmbedIdStatus(embedId, update);
  };

  const handleToggleClick = (data) => {
    if (data.isActive) {
      setSelectedEmbedIdDataToDisable(data);
      toggleDisableEmbedIdModal();
    } else {
      toggleEmbedId(data);
    }
  };

  const columns = useMemo(
    () => [
      {
        Header: "Question",
        id: "question",
        acessor: "question",
        Cell: (row) => {
          return row.row.original.question?.questionText;
        },
      },
      {
        Header: "Embed Id",
        accessor: "_id",
        Cell: (row) => {
          return (
            <div className="d-flex justify-content-between">
              {row.row.original._id}
              <CopyToClipboard textToCopy={row.row.original._id} />
            </div>
          );
        },
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: (row) => {
          return row.row.original.isActive ? "Active" : "Inactive";
        },
      },
      {
        Header: "Date Created",
        accessor: "timeCreated",
        Cell: (row) => {
          return format(new Date(row.row.original.timeCreated), "MMM dd, yyyy");
        },
      },
      {
        Header: "Created By",
        id: "owner",
        Cell: (row) => {
          return row.row.original.createdBy.roles.account.name;
        },
        canSort: true,
      },
      {
        Header: "",
        id: "actions",
        Cell: (row) => {
          return (
            <div className="d-flex justify-content-end">
              <div>
                {row.row.original.isActive ? (
                  <button className="btn btn-sm btn-outline-danger" onClick={() => handleToggleClick(row.row.original)}>
                    <i className="tio-link-off"></i> Disable
                  </button>
                ) : (
                  <button
                    className="btn btn-sm btn-outline-success"
                    onClick={() => handleToggleClick(row.row.original)}
                  >
                    <i className="tio-link"></i> Enable
                  </button>
                )}
              </div>
            </div>
          );
        },
        sortable: false,
        filterable: false,
      },
    ],
    []
  );

  if (!embedIdData?.length) {
    return <span>No embed IDs generated yet.</span>;
  }

  return (
    <>
      <Table
        columns={columns}
        data={embedIdData ?? []}
        items={items ?? []}
        loading={loading}
        pageCount={pages?.total ?? 0}
        refresh={refresh}
        cancelRefresh={() => setRefresh(false)}
      />
      {showDisableEmbedIdModal && (
        <ModalDisableEmbedId
          show={showDisableEmbedIdModal}
          handleClose={toggleDisableEmbedIdModal}
          embedIdData={selectedEmbedIdDataToDisable}
          disableEmbedId={toggleEmbedId}
        />
      )}
    </>
  );
};

export default memo(QuestionEmbedIdsTable);
