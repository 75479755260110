import React, { memo, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { format } from "date-fns";
import classNames from "classnames";
import { Badge, Button, Card } from "react-bootstrap";
import DatasourceActions from "redux/actions/datasource";
import FewshotActions from "redux/actions/fewshot";
import Table from "components/table/table";
import Loading from "components/loading";
import ModalAddOrEditNlqExample from "components/modal/modal-add-edit-nlq-example";
import ModalDeleteNlqExample from "components/modal/modal-delete-nlq-example";
import { fewshotTypes } from "helpers/constants";
import FilterNlqExample from "./examples/filter-nlq-example";
import ModalAddOrEditExampleCategory from "components/modal/modal-add-edit-example-category";
import CategoryActions from "redux/actions/fewshot-category";
import fewshotStyles from "./datasource.module.scss";

const INITIAL_PAGE_SIZE = 20;

const NlqExamples = ({ datasource }) => {
  const { fewshots, loading, fewshotCategories } = useSelector((s) => s.datasource);
  const { data, items, pages } = fewshots || {};
  const { showDeleteSuccess, showUpdateSuccess, showSaveSuccess } = useSelector((s) => s.fewshot);
  const {
    showDeleteSuccess: showCategoryDeleteSuccess,
    showUpdateSuccess: showCategoryUpdateSuccess,
    showSaveSuccess: showCategorySaveSuccess,
  } = useSelector((s) => s.fewshotCategories);

  const [showModal, setShowModal] = useState(false);
  const [selectedFewshot, setSelectedFewshot] = useState();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [fewshotToDelete, setFewshotToDelete] = useState();
  const [refresh, setRefresh] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pagesize, setPagesize] = useState(10);
  const [pageindex, setPageindex] = useState(0);
  const [selectedFilterType, setSelectedFilterType] = useState();
  const [selectedFilterCategories, setSelectedFilterCategories] = useState([]);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [categories, setCategories] = useState(fewshotCategories);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [customFilters, setCustomFilters] = useState({
    type: selectedFilterType,
    categories: selectedFilterCategories?.map((c) => c.id),
  });
  const [activeFilterCount, setActiveFilterCount] = useState(0);

  const toggleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    const filters = {
      type: selectedFilterType,
      categories: selectedFilterCategories?.map((c) => c.id),
    };

    setCustomFilters(filters);
  }, [selectedFilterType, selectedFilterCategories]);

  useEffect(() => {
    setCategories(fewshotCategories);
  }, [fewshotCategories]);

  const fetchData = useCallback(({ pageSize, pageIndex, sort, filtered, customFilters }) => {
    setPageindex(pageIndex);
    setPagesize(pageSize);

    DatasourceActions.getFewshots(datasource?._id, {
      limit: pageSize,
      page: pageIndex + 1,
      sort,
      q: filtered,
      customFilters,
    });
  }, []);

  useEffect(() => {
    DatasourceActions.getFewshotCategories(datasource?._id);
  }, [datasource?._id]);

  useEffect(() => {
    if (!data) {
      fetchData({ pageSize: INITIAL_PAGE_SIZE, pageIndex: 0, filtered: searchQuery });
    }
  }, [data]);

  useEffect(() => {
    if (showCategorySaveSuccess || showCategoryDeleteSuccess || showCategoryUpdateSuccess) {
      DatasourceActions.getFewshotCategories(datasource?._id);
      CategoryActions.clearSuccessState();
    }
  }, [showCategorySaveSuccess, showCategoryDeleteSuccess, showCategoryUpdateSuccess]);

  const refreshData = () => {
    setRefresh(true);

    fetchData({
      pageSize: INITIAL_PAGE_SIZE,
      pageIndex: !pages || pages?.hasNext || pages.current === 1 ? currentPage - 1 : currentPage - 2,
      filtered: searchQuery,
      customFilters,
    });
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setShowDeleteModal(false);
    setSelectedFewshot();
    setFewshotToDelete();
  };

  useEffect(() => {
    if (showUpdateSuccess) {
      refreshData();
      FewshotActions.clearFewshotSuccessState();
    }
  }, [showUpdateSuccess]);

  useEffect(() => {
    if (showSaveSuccess) {
      refreshData();
      FewshotActions.clearFewshotSuccessState();
    }
  }, [showSaveSuccess]);

  useEffect(() => {
    if (showDeleteSuccess) {
      handleCloseModal();
      refreshData();
      FewshotActions.clearFewshotSuccessState();
    }
  }, [showDeleteSuccess]);

  const onClickEdit = (fewshot) => {
    setSelectedFewshot(fewshot);
    setShowModal(true);
  };

  const onClickDelete = (fewshot) => {
    setFewshotToDelete(fewshot);
    setShowDeleteModal(true);
  };

  const onClickDeleteConfirm = () => {
    FewshotActions.delete(fewshotToDelete?._id);
  };

  const columns = React.useMemo(
    () => [
      {
        Header: () => (
          <div style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>#</div>
        ),
        accessor: "id",
        maxWidth: 100,
        Cell: (row) => {
          return (
            <div style={{ maxWidth: "100px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
              {pageindex * pagesize + row.row.index + 1}
            </div>
          );
        },
      },
      {
        Header: () => (
          <div style={{ maxWidth: "300px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            Question Text
          </div>
        ),
        accessor: "questionText",
        maxWidth: 300,
        Cell: (row) => {
          return (
            <div
              className="text-wrap"
              style={{ maxWidth: "300px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            >
              {row.row.original.analysisName || row.row.original.prompt || ""}
            </div>
          );
        },
      },
      {
        Header: () => (
          <div style={{ maxWidth: "300px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            Categories
          </div>
        ),
        accessor: "categories",
        maxWidth: 300,
        padding: 0,
        paddingLeft: 0,
        Cell: (row) => {
          return (
            <div
              className="text-wrap"
              style={{
                maxWidth: "300px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                padding: 0,
              }}
            >
              {row.row.original.categories?.length ? (
                row.row.original.categories.map((cat, index) => (
                  <Badge
                    key={cat._id}
                    pill
                    style={{
                      backgroundColor: cat.color,
                      color: "#5F768F",
                      marginRight: index === row.row.original.categories.length - 1 ? "0" : "0.5rem",
                    }}
                  >
                    {cat.name}
                  </Badge>
                ))
              ) : (
                <></>
              )}
            </div>
          );
        },
      },
      {
        Header: () => (
          <div style={{ maxWidth: "550px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            SQL
          </div>
        ),
        accessor: "sql",
        maxWidth: 550,
        Cell: (row) => {
          const [isTruncated, setIsTruncated] = useState(true);
          const truncatedText =
            row.row.original.response?.length > 150
              ? row.row.original.response.substring(0, 150) + "..."
              : row.row.original.response;

          return (
            <div
              className="text-wrap"
              style={{ maxWidth: "550px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            >
              {isTruncated ? truncatedText : row.row.original.response || ""}
              {row.row.original.response?.length > 150 && (
                <span
                  style={{ color: "#1d73c9", cursor: "pointer", marginLeft: "5px" }}
                  onClick={() => setIsTruncated(!isTruncated)}
                >
                  {isTruncated ? "View More" : "View Less"}
                </span>
              )}
            </div>
          );
        },
      },
      {
        Header: () => (
          <div style={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            Type
          </div>
        ),
        accessor: "type",
        maxWidth: 200,
        Cell: (row) => {
          const fewshotType = fewshotTypes.filter((f) => f.value === row.row.original.type)[0];
          return (
            <div
              className="text-wrap"
              style={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            >
              {fewshotType ? fewshotType.label : "Regular"}
            </div>
          );
        },
      },
      {
        Header: () => (
          <div style={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            Date Updated
          </div>
        ),
        accessor: "timeUpdated",
        maxWidth: 200,
        Cell: (row) => {
          return format(new Date(row.row.original.timeUpdated || row.row.original.timeCreated), "MMM dd, yyyy");
        },
      },
      {
        Header: () => (
          <div style={{ maxWidth: "200px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}>
            Actions
          </div>
        ),
        accessor: "Actions",
        id: "actions",
        maxWidth: 150,
        Cell: (row) => {
          return (
            <div
              className="d-flex align-items-center"
              style={{ maxWidth: "150px", overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap" }}
            >
              <button className="btn btn-sm btn-white" onClick={() => onClickEdit(row.row.original)}>
                <i className="tio-edit" />
              </button>
              <button className="btn btn-sm btn-outline-danger ml-2" onClick={() => onClickDelete(row.row.original)}>
                <i className="tio-delete-outlined" />
              </button>
            </div>
          );
        },
        sortable: false,
        filterable: false,
      },
    ],
    [pageindex, pagesize]
  );

  const onChangeSearch = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    const timeOutId = setTimeout(() => setSearchQuery(searchValue), 300);
    return () => clearTimeout(timeOutId);
  }, [searchValue]);

  const countActiveFilters = () => {
    let filterCount = 0;

    if (selectedFilterType) {
      filterCount++;
    }

    if (selectedFilterCategories?.length > 0) {
      filterCount = filterCount + selectedFilterCategories.length;
    }

    return filterCount;
  };

  const onClickApplyFilters = () => {
    setRefresh(true);

    fetchData({
      pageSize: INITIAL_PAGE_SIZE,
      pageIndex: 0,
      filtered: searchQuery,
      customFilters,
    });

    setActiveFilterCount(countActiveFilters());
    toggleDropdown();
  };

  return (
    <div className={fewshotStyles.fewshots}>
      <div className="text-dark mb-4">
        <i className={classNames("tio-info-outlined", fewshotStyles.infoIcon)} /> Examples help AI understand your
        dataset better.
      </div>
      <Card>
        <Card.Header className="d-flex flex-md-row flex-column align-items-start justify-content-md-between">
          <div className="flex w-100 w-md-50 pb-3 pb-md-0">
            <div className="input-group input-group-merge input-group-flush">
              <div className="input-group-prepend">
                <div className="input-group-text">
                  <i className="tio-search"></i>
                </div>
              </div>
              <input
                type="search"
                value={searchValue}
                onChange={onChangeSearch}
                className="form-control"
                placeholder="Search examples"
                aria-label="Search examples"
              />
            </div>
          </div>
          <div className="d-flex">
            <div className="d-flex">
              <div className="dropdown">
                <Button
                  variant="outline-secondary"
                  className={classNames("mr-2 dropdown-toggle", fewshotStyles.exampleFilterButton)}
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  onClick={toggleDropdown}
                >
                  <i className="tio-filter mr-1" style={{ marginTop: "2px" }} /> Filters
                  {activeFilterCount ? (
                    <Badge
                      style={{ backgroundColor: "#71869d", marginLeft: "6px", marginTop: "2px", borderRadius: "10rem" }}
                    >
                      {activeFilterCount}
                    </Badge>
                  ) : (
                    <></>
                  )}
                </Button>
                <div
                  className={classNames(
                    "dropdown-menu dropdown-card dropdown-menu-left dropdown-menu-md-right example-filter-dropdown",
                    fewshotStyles.exampleFilterDropdown,
                    { show: dropdownOpen }
                  )}
                >
                  <FilterNlqExample
                    datasource={datasource}
                    categories={categories || []}
                    onClickEditList={() => setShowAddCategoryModal(true)}
                    selectedFilterType={selectedFilterType}
                    selectedFilterCategories={selectedFilterCategories}
                    setSelectedFilterCategories={setSelectedFilterCategories}
                    setSelectedFilterType={setSelectedFilterType}
                    toggleDropdown={toggleDropdown}
                    onClickApplyFilters={onClickApplyFilters}
                  />
                </div>
              </div>
            </div>
            <Button variant="primary" onClick={() => setShowModal(true)}>
              <i className="tio-add" /> Add New Example
            </Button>
          </div>
        </Card.Header>
        <Card.Body>
          <Loading loading={loading}></Loading>
          <Table
            columns={columns}
            data={data ?? []}
            items={items ?? []}
            loading={loading}
            filtered={searchQuery}
            fetchData={fetchData}
            pageCount={pages?.total ?? 0}
            refresh={refresh}
            cancelRefresh={() => setRefresh(false)}
            setCurrentPage={setCurrentPage}
            initialPageSize={INITIAL_PAGE_SIZE}
            customFilters={customFilters}
          />
          {!loading && !data?.length ? (
            <span>Datasource doesn&apos;t have any examples for natural language questions.</span>
          ) : (
            <></>
          )}
        </Card.Body>
        {showModal && (
          <ModalAddOrEditNlqExample
            show={showModal}
            handleClose={handleCloseModal}
            selectedFewshot={selectedFewshot}
            datasourceId={datasource?._id}
            datasource={datasource}
            categories={categories || []}
            onClickEditList={() => setShowAddCategoryModal(true)}
          />
        )}
        {showDeleteModal && (
          <ModalDeleteNlqExample
            show={showDeleteModal}
            handleClose={handleCloseModal}
            onClickDeleteConfirm={onClickDeleteConfirm}
            fewshotToDelete={fewshotToDelete}
          />
        )}
        {showAddCategoryModal && (
          <ModalAddOrEditExampleCategory
            show={showAddCategoryModal}
            handleClose={() => setShowAddCategoryModal(false)}
            categories={categories || []}
            datasource={datasource}
          />
        )}
      </Card>
    </div>
  );
};

export default memo(NlqExamples);
