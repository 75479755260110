import React, { useEffect, useRef, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { Button } from "react-bootstrap";
import { useMediaQuery } from "react-responsive";
import { useSelector } from "react-redux";
import Select from "react-select";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  datasources,
  datasourceTypes,
  DATASOURCES_WITH_SSHTUNNEL,
  GOOGLE_SHEETS_URL_REGEX,
  SNOWFLAKE_HOSTNAME_REGEX,
  SNOWFLAKE_DOMAIN_REGEX,
  DATASOURCES_WITH_SSL_CUSTOMCERTS,
  // msSqlAuthTypes,
} from "helpers/constants";

import Actions from "redux/actions/datasource";
import QueryManagerActions from "redux/actions/query-manager";
import OrganizationActions from "redux/actions/organization";
import UserActions from "redux/actions/user";

import Alert from "components/alert";
import Loading from "components/loading";
import DataSourceTables from "components/datasource/tables";
import Unauthorized from "components/unauthorized";
import Footer from "components/footer/footermain";
import Header from "components/navbar/header";

import { getDatasourceObject } from "../../helpers/datasource.helper";

const scrollToRef = (ref) => ref.current.scrollIntoView({ behavior: "smooth" });
const containsHttpsSnowflakeHost = (string) => SNOWFLAKE_HOSTNAME_REGEX.test(string);
const containsSnowflakeDomain = (string) => SNOWFLAKE_DOMAIN_REGEX.test(string);

const schema = yup.object().shape({
  type: yup.string().required("Type is required"),
  organization: yup.string().optional(),
  host: yup
    .string()
    .when("type", {
      is: (type) => type === datasourceTypes.SNOWFLAKE,
      then: yup
        .string()
        .max(100, "Host cannot be longer than 100 characters")
        .required("Host is required")
        .test(
          "not include https",
          "Do not include the https:// or the 'snowflakecomputing.com' portion of your Snowflake Host",
          (value) => !containsHttpsSnowflakeHost(value) && !containsSnowflakeDomain(value)
        ),
    })
    .when("type", {
      is: (type) => type !== datasourceTypes.GOOGLE_BIGQUERY && type !== datasourceTypes.GOOGLE_SHEETS,
      then: yup.string().max(100, "Host cannot be longer than 100 characters").required("Host is required"),
    }),
  port: yup
    .string()
    .nullable(true)
    .max(20, "Port cannot be longer than 20 characters")
    .matches(/^[0-9]+$/, "Port must be numeric")
    .transform((v, o) => (o === "" ? null : v)),
  username: yup.string().max(100, "Username cannot be longer than 100 characters").optional(),
  password: yup.string().when(["type", "username"], {
    is: (type, username) =>
      type !== datasourceTypes.CLICKHOUSE && type !== datasourceTypes.SNOWFLAKE && username?.length > 0,
    then: yup.string().required("Password is required"),
  }),
  privatekeysnowflake: yup.string().when(["type", "username", "password"], {
    is: (type, username, password) =>
      type === datasourceTypes.SNOWFLAKE && username?.length > 0 && password?.length < 0,
    then: yup.string().required("Private key is required"),
  }),
  databasename: yup.string().when("type", {
    is: (type) =>
      type !== datasourceTypes.GOOGLE_BIGQUERY &&
      type !== datasourceTypes.GOOGLE_SHEETS &&
      type !== datasourceTypes.UPLOADED_CSV &&
      type !== datasourceTypes.TRINO,
    then: yup
      .string()
      .max(100, "Database Name cannot be longer than 100 characters")
      .required("Database Name is required"),
  }),
  displayName: yup.string().when("type", {
    is: (type) => type === datasourceTypes.TRINO,
    then: yup
      .string()
      .max(100, "Display Name cannot be longer than 100 characters")
      .required("Display Name is required"),
  }),
  projectId: yup.string().when("type", {
    is: (type) => type === datasourceTypes.GOOGLE_BIGQUERY,
    then: yup.string().max(100, "Project Id cannot be longer than 100 characters").required("Project Id is required"),
  }),
  datasetId: yup.string().when("type", {
    is: (type) => type === datasourceTypes.GOOGLE_BIGQUERY,
    then: yup.string().max(100, "Dataset Id cannot be longer than 100 characters").required("Dataset Id is required"),
  }),
  serviceAccount: yup.string().when("type", {
    is: (type) => type === datasourceTypes.GOOGLE_BIGQUERY,
    then: yup.string().required("Service Account is required"),
  }),
  location: yup.string().optional("", null),
  sheetUrl: yup.string().when("type", {
    is: (type) => type === datasourceTypes.GOOGLE_SHEETS,
    then: yup.string().required("Sheet URL is required").matches(GOOGLE_SHEETS_URL_REGEX, "Enter a valid url"),
  }),
  catalog: yup.string().when("type", {
    is: (type) => type === datasourceTypes.TRINO || type === datasourceTypes.PRESTO,
    then: yup.string().required("Catalog is required"),
  }),
  schema: yup.string().when("type", {
    is: (type) => type === datasourceTypes.TRINO || type === datasourceTypes.PRESTO,
    then: yup.string().required("Schema is required"),
  }),
  ssl: yup.bool().optional(),
  sslwithcerts: yup.bool().optional(),
  sslserverrootcert: yup.string().optional(),
  sslclientkey: yup.string().optional(),
  sslclientcert: yup.string().optional(),
  sshtunnel: yup.bool().optional(),
  sshtunnelusername: yup.string().when("sshtunnel", {
    is: true,
    then: yup
      .string()
      .required("SSH username is required when connecting over SSH")
      .max(100, "SSH username cannot be longer than 100 characters"),
  }),
  sshtunnelpassword: yup.string().when("sshtunnelusername", {
    is: (username) => username.length > 0,
    then: yup.string().optional(),
  }),
  sshprivatekey: yup.string().when("sshtunnel", {
    is: true,
    then: yup.string().required("Private Key is required when connecting over SSH"),
  }),
  sshtunnelport: yup.string().when("sshtunnel", {
    is: true,
    then: yup.string().optional(),
  }),
  httpPath: yup.string().when("type", {
    is: (type) => type === datasourceTypes.DATABRICKS,
    then: yup.string().required("HTTP path is required"),
  }),
  token: yup.string().when("type", {
    is: (type) => type === datasourceTypes.DATABRICKS,
    then: yup.string().required("Token is required"),
  }),
  authType: yup.string().when("type", {
    is: (type) => type === datasourceTypes.MICROSOFT_SQL,
    then: yup.string().optional(),
  }),
  encrypt: yup.bool().optional(),
  clientId: yup.string().when(["type", "authType"], {
    is: (type, authType) =>
      type === datasourceTypes.MICROSOFT_SQL &&
      (authType === "azure-active-directory-msi-app-service" ||
        authType === "azure-active-directory-msi-vm" ||
        authType === "azure-active-directory-service-principal-secret"),
    then: yup.string().required("Client Id is required"),
  }),
  msiEndpoint: yup.string().when(["type", "authType"], {
    is: (type, authType) =>
      type === datasourceTypes.MICROSOFT_SQL &&
      (authType === "azure-active-directory-msi-app-service" || authType === "azure-active-directory-msi-vm"),
    then: yup.string().required("MSI endpoint is required"),
  }),
  msiSecret: yup.string().when(["type", "authType"], {
    is: (type, authType) =>
      type === datasourceTypes.MICROSOFT_SQL && authType === "azure-active-directory-msi-app-service",
    then: yup.string().required("MSI endpoint is required"),
  }),
  domain: yup.string().when(["type", "authType"], {
    is: (type, authType) =>
      type === datasourceTypes.MICROSOFT_SQL && (authType === "azure-active-directory-password" || authType === "ntlm"),
    then: yup.string().required("Domain is required"),
  }),
  clientSecret: yup.string().when(["type", "authType"], {
    is: (type, authType) =>
      type === datasourceTypes.MICROSOFT_SQL && authType === "azure-active-directory-service-principal-secret",
    then: yup.string().required("Client secret is required"),
  }),
  tenantId: yup.string().when(["type", "authType"], {
    is: (type, authType) =>
      type === datasourceTypes.MICROSOFT_SQL && authType === "azure-active-directory-service-principal-secret",
    then: yup.string().required("Tenant Id is required"),
  }),
  warehouse: yup.string().when("type", {
    is: (type) => type === datasourceTypes.SNOWFLAKE,
    then: yup.string().optional(),
  }),
});

const DatasourceForm = () => {
  const user = useSelector((s) => s.login.user);
  const { id } = useParams();
  const { loading, datasource, error, showSaveSuccess, showSaveTablesSuccess } = useSelector((s) => s.datasource);
  const organizationsByRole = useSelector((s) => s.organization.organizationsByRole);
  const organizationsByRoleOptions = useMemo(
    () => organizationsByRole?.map((org) => ({ label: org.name, value: org._id })),
    [organizationsByRole]
  );
  const isMobile = useMediaQuery({ query: "(max-width: 480px)" });

  const cancel = () => {
    window.location = "/datasource";
  };

  useEffect(() => {
    if (
      datasource?._id &&
      (datasource.type === datasourceTypes.GOOGLE_SHEETS || datasource.type === datasourceTypes.GOOGLE_SHEETS_LIVE)
    ) {
      cancel();
    }
  });

  useEffect(() => {
    OrganizationActions.getOrganizationsByRole("admin");
  }, []);

  const {
    _id,
    host,
    password,
    privatekeysnowflake,
    port,
    databasename,
    schema: schemaname,
    warehouse: warehousename,
    type,
    organizationId,
    username,
    googleBigQuery,
    googleSheets,
    trino,
    presto,
    settings,
    ssl,
    tables,
    sshtunnel,
    sshtunnelusername,
    sshtunnelpassword,
    sshprivatekey,
    databricks,
    mssql,
    sslwithcerts,
    sslserverrootcert,
    sslclientkey,
    sslclientcert,
    sshtunnelport,
    displayName,
  } = { ...datasource };

  const { authType, encrypt, clientId, msiEndpoint, msiSecret, domain, clientSecret, tenantId } = { ...mssql } || {};

  const handleChange = (event) => {
    if (event) {
      setValue("type", event.value, { shouldValidate: true });
      setDataType(event.value);

      if (
        event.value === datasourceTypes.POSTGRES ||
        event.value === datasourceTypes.MYSQL ||
        event.value === datasourceTypes.CLICKHOUSE
      ) {
        setValue("port", "", { shouldValidate: true });
      } else if (
        type === datasourceTypes.TRINO ||
        type === datasourceTypes.PRESTO ||
        type === datasourceTypes.DATABRICKS
      ) {
        setValue("port", 443, { shouldValidate: true });
      }
    } else {
      setValue("type", "", { shouldValidate: true });
      setDataType("");
    }
  };

  const handleOrganizationChange = (event) => {
    if (event) {
      setValue("organization", event.value, { shouldValidate: true });
    } else {
      setValue("organization", "", { shouldValidate: true });
    }
  };

  const { register, handleSubmit, errors, setValue, control } = useForm({
    resolver: yupResolver(schema),
  });

  const {
    loading: loadingQuery,
    jobId,
    jobState,
    queryResult,
    timeoutError,
    error: queryManagerError,
    connectionErr,
  } = useSelector((s) => s.queryManager);
  const { isAdminOfOrg, loading: loadingUserRole } = useSelector((s) => s.user);

  const errorsRef = useRef(null);
  const executeScroll = () => scrollToRef(errorsRef);

  // eslint-disable-next-line no-console
  const onError = (errors, e) => console.log("onError", errors, e);

  const [datasourceForm, setDatasourceForm] = useState({});
  const [alerts, setAlerts] = useState([]);
  const [dataType, setDataType] = useState("");
  const [includeAll, setIncludeAll] = useState(false);
  const [tablesToSelect, setTablesToSelect] = useState([]);
  const [step, setStep] = useState(0);
  const [authTypeValue, setAuthTypeValue] = useState(authType);
  const [encryptValue, setEncrypt] = useState(true);

  const handleEncrypt = () => setEncrypt(!encryptValue);

  const doSave = (data, e) => {
    e.preventDefault();

    // Remove when enabling different auth types
    if (!data.authType) {
      data.authType = "default";
    }

    if (organizationsByRoleOptions.length && !data.organization) {
      data.organization = organizationsByRoleOptions.find((c) => c.value === organizationId)?.value;
    }

    if (data.sslwithcerts) {
      data.ssl = true;

      if (!data.sslclientcert && !data.sslclientkey && !data.sslserverrootcert) {
        data.sslwithcerts = false;
      }
    }

    if (data.type === datasourceTypes.POSTGRES) {
      if (data.schemaname) {
        data.schema = data.schemaname;
      } else {
        data.schema = "public";
      }

      delete data.schemaname;
    }

    if (data.type === datasourceTypes.SNOWFLAKE) {
      if (data.warehousename) {
        data.warehouse = data.warehousename;
      }

      delete data.warehousename;
    }

    const _datasource = getDatasourceObject(data);

    setAlerts([]);
    setDatasourceForm(_datasource);

    QueryManagerActions.checkConnection({
      datasource: _datasource,
      version: 2,
    });
  };

  const updateTables = () => {
    Actions.updateTables(_id, {
      settings: {
        includeAllTables: includeAll,
      },
      tables: tablesToSelect.map((table) => ({
        tableName: table?.tableName,
        schemaName: table.schemaName,
        warehouseName: table.warehouseName,
        labelName: table.labelName,
        included: table.included,
        columnSchema: table.columnSchema,
        ...(table.lookupEmailField ? { lookupEmailField: table.lookupEmailField } : {}),
      })),
    });
  };

  const handleInclude = (e, index) => {
    const table = tablesToSelect[index];

    table.included = e.target.checked;

    const _tables = [...tablesToSelect];

    if (!e.target.checked) {
      setIncludeAll(false);
    }

    setTablesToSelect(_tables);
  };

  const handleIncludeAll = (e) => {
    setIncludeAll(e.target.checked);

    for (const table of tablesToSelect) {
      table.included = e.target.checked;
    }
    setTablesToSelect([...tablesToSelect]);
  };

  const [sshTunnelDiv, setSSHTunnelDiv] = useState(sshtunnel);
  const handleShowHideSSH = () => setSSHTunnelDiv(!sshTunnelDiv);

  const [sslWithCertsDiv, setSSLWithCertsDiv] = useState(sslwithcerts);
  const handleShowHideSSLWithCerts = () => setSSLWithCertsDiv(!sslWithCertsDiv);

  useEffect(() => {
    if (jobId) {
      QueryManagerActions.getQueryById(jobId);
    }
  }, [jobId]);

  useEffect(() => {
    if (timeoutError || (queryResult !== undefined && queryResult !== null && !Array.isArray(queryResult))) {
      const newAlerts = alerts.concat(
        <Alert
          key="danger"
          type="danger"
          message="Connection to database failed, please check your connection settings."
          details={connectionErr}
        />
      );

      setAlerts(newAlerts);

      executeScroll();
    } else if (jobState === "completed") {
      const lookupTable = tables?.filter((t) => t.lookupEmailField);

      let _tables = queryResult.map((c) => {
        const tableFields = {
          tableName: c.table_name,
          schemaName: c.schema_name,
          labelName: c.label_name,
          included: settings.includeAllTables,
          show: true,
          columnSchema: c.column_schema,
        };

        if (lookupTable?.length && c.table_name === lookupTable[0].tableName) {
          tableFields.lookupEmailField = lookupTable[0].lookupEmailField;
        }

        return tableFields;
      });

      if (!settings.includeAllTables) {
        // eslint-disable-next-line no-sequences
        const tablesMap = _tables.reduce((map, obj) => ((map[obj?.tableName] = obj), map), {});

        if (tables) {
          for (const table of tables) {
            const tableMap = tablesMap[table?.tableName];
            if (tableMap) {
              tablesMap[table?.tableName].included = table.included;
            }
          }
        }

        _tables = Object.values(tablesMap);
      }

      setTablesToSelect(_tables);

      Actions.update(_id, datasourceForm);
    }
  }, [jobState, queryResult, timeoutError]);

  useEffect(() => {
    if (type) {
      const typeOfDatasource = type === datasourceTypes.GOOGLE_SHEETS_LIVE ? "google_sheets" : type;
      setValue("type", typeOfDatasource, { shouldValidate: true });
      setDataType(type);
    }
  }, [setValue, type]);

  useEffect(() => {
    if (authType) {
      setValue("authType", authType, { shouldValidate: true });
      setAuthTypeValue(authType);
    }
  }, [authType, setValue]);

  useEffect(() => {
    if (encrypt) {
      setValue("encrypt", encrypt, { shouldValidate: true });
      setEncrypt(encrypt);
    }
  }, [encrypt, setValue]);

  useEffect(() => {
    setValue("sslwithcerts", sslwithcerts, { shouldValidate: true });
    setSSLWithCertsDiv(sslwithcerts);
  }, [setValue, sslwithcerts]);

  useEffect(() => {
    if (showSaveSuccess) {
      setStep(1);
    }
  }, [showSaveSuccess]);

  useEffect(() => {
    if (settings) {
      setIncludeAll(settings.includeAllTables);
    }
  }, [settings]);

  // equivalent for componentWillMount
  useEffect(() => {
    window.dashboardOnReady();

    Actions.getDataSourceByIdForEditing(id);

    // lets get the body classes ready for Dashboard
    const origBodyClass = document.body.className;
    document.body.className = "main";

    return () => {
      // put componentUnMount logic in here
      document.body.className = origBodyClass;
    };
  }, []);

  useEffect(() => {
    if (error || queryManagerError) {
      setAlerts([<Alert key="danger" type="danger" message={error ?? queryManagerError} />]);

      executeScroll();
    } else if (showSaveSuccess || showSaveTablesSuccess) {
      setAlerts([]);
      executeScroll();

      if (showSaveTablesSuccess) window.location = "/datasource";
    }
  }, [error, queryManagerError, showSaveSuccess, showSaveTablesSuccess]);

  useEffect(() => {
    if (datasource?.organizationId && !isAdminOfOrg) {
      UserActions.getUserRole(datasource?.organizationId);
    }
  }, [datasource]);

  if (!loadingUserRole && !loading && isAdminOfOrg && !isAdminOfOrg.isAdmin) {
    return <Unauthorized backPath="/datasource" />;
  }

  return (
    <>
      <Header user={user} />

      <main id="content" role="main" className="main" style={isMobile ? { paddingTop: "7.75rem" } : {}}>
        <div className="content container-fluid">
          <div className="page-header pb-0">
            <div className="row align-items-center">
              <div className="col-sm mb-2 mb-sm-0">
                <h1 className="page-title">Edit Data Source</h1>
              </div>

              <div className="col-sm-auto"></div>
            </div>
          </div>

          <div className="row justify-content-lg-center">
            <div className="col-lg-6">
              <div className="card">
                <Loading loading={loading || loadingQuery}></Loading>

                <div className="card-body" ref={errorsRef}>
                  {alerts}

                  <form className="js-step-form">
                    <ul
                      id="createDataSourceStepFormProgress"
                      className="js-step-progress step step-sm step-icon-sm step-inline step-item-between mb-7"
                    >
                      <li className={`step-item ${step === 0 ? " active" : ""}`}>
                        <a className="step-content-wrapper">
                          <span className="step-icon step-icon-soft-dark">1</span>
                          <div className="step-content">
                            <span className="step-title">Configure</span>
                          </div>
                        </a>
                      </li>

                      <li className={`step-item ${step === 1 ? " active" : ""}`}>
                        <a className="step-content-wrapper" onClick={handleSubmit(doSave, onError)}>
                          <span className="step-icon step-icon-soft-dark">2</span>
                          <div className="step-content">
                            <span className="step-title">Tables</span>
                          </div>
                        </a>
                      </li>
                    </ul>

                    <div style={step === 0 ? {} : { display: "none" }}>
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label htmlFor="projectNameNewProjectLabel" className="input-label">
                              Select Data Source
                            </label>

                            <Controller
                              name="type"
                              control={control}
                              render={({ value, ref }) => (
                                <Select
                                  styles={{
                                    menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                  }}
                                  className={errors.type ? " is-invalid" : ""}
                                  inputRef={ref}
                                  options={datasources}
                                  isClearable
                                  value={datasources.find((c) => c.value === value)}
                                  defaultValue={datasources.find((c) => c.value === type)}
                                  onChange={handleChange}
                                  maxMenuHeight={450}
                                />
                              )}
                            />
                            <span className="invalid-feedback">{errors.type?.message}</span>
                          </div>
                        </div>
                      </div>

                      {organizationId && organizationsByRoleOptions && organizationsByRole?.length > 0 && (
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label htmlFor="dataSourceNewOrganizationLabel" className="input-label">
                                Select Organization
                              </label>
                              <Controller
                                name="organization"
                                control={control}
                                render={({ value, ref }) => (
                                  <Select
                                    styles={{
                                      menu: (provided) => ({ ...provided, zIndex: 9999 }),
                                    }}
                                    className={errors.organization ? " is-invalid" : ""}
                                    inputRef={ref}
                                    options={organizationsByRoleOptions}
                                    isClearable
                                    value={organizationsByRoleOptions.find((c) => c.value === value)}
                                    defaultValue={organizationsByRoleOptions.find((c) => c.value === organizationId)}
                                    onChange={handleOrganizationChange}
                                  />
                                )}
                              />
                              <span className="invalid-feedback">{errors.organization?.message}</span>
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="row">
                        <div className="col-sm-12">
                          <div className="form-group">
                            <label htmlFor="dataSourceNewHostLabel" className="input-label">
                              {dataType === datasourceTypes.TRINO ? "Display Name (Required)" : "Display Name"}
                            </label>
                            <div
                              className={"input-group input-group-merge" + (errors.displayName ? " is-invalid" : "")}
                            >
                              <div className="input-group-prepend">
                                <div className="input-group-text">
                                  <i className="tio-documents" />
                                </div>
                              </div>
                              <input
                                type="text"
                                className={"form-control " + (errors.host ? " is-invalid" : "")}
                                name="displayName"
                                id="displayName"
                                placeholder="Enter display name..."
                                aria-label="Enter display name here"
                                ref={register}
                                defaultValue={displayName}
                              />
                            </div>
                            <span className="invalid-feedback">{errors.displayName?.message}</span>
                          </div>
                        </div>
                      </div>

                      <div
                        style={
                          dataType !== datasourceTypes.GOOGLE_BIGQUERY && dataType !== datasourceTypes.GOOGLE_SHEETS
                            ? {}
                            : { display: "none" }
                        }
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label htmlFor="dataSourceNewHostLabel" className="input-label">
                                Hostname{" "}
                                <i
                                  className="tio-help-outlined text-body ml-1"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="The name of the server where your database is located"
                                />
                              </label>
                              <div className={"input-group input-group-merge" + (errors.host ? " is-invalid" : "")}>
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-briefcase-outlined" />
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className={"form-control " + (errors.host ? " is-invalid" : "")}
                                  name="host"
                                  id="dataSourceNewHostLabel"
                                  placeholder="127.0.0.1"
                                  aria-label="Enter host name here"
                                  ref={register}
                                  defaultValue={host}
                                />
                              </div>
                              <span className="invalid-feedback">{errors.host?.message}</span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          {dataType !== datasourceTypes.SNOWFLAKE && type !== datasourceTypes.MICROSOFT_SQL && (
                            <div className={dataType === datasourceTypes.TRINO ? "col-sm-12" : "col-sm-6"}>
                              <div className="form-group">
                                <label htmlFor="dataSourcePortLabel" className="input-label">
                                  Port
                                </label>
                                <div className="input-group input-group-merge">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-monitor" />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className={"form-control " + (errors.port ? " is-invalid" : "")}
                                    name="port"
                                    id="dataSourcePortLabel"
                                    placeholder="3306"
                                    aria-label="Enter port number here"
                                    ref={register}
                                    defaultValue={port}
                                  />
                                  <span className="invalid-feedback">{errors.port?.message}</span>
                                </div>
                              </div>
                            </div>
                          )}
                          {dataType !== datasourceTypes.TRINO && (
                            <div className={type === datasourceTypes.MICROSOFT_SQL ? "col-sm-12" : "col-sm-6"}>
                              <div className="form-group">
                                <label htmlFor="dataSourceDatabaseLabel" className="input-label">
                                  {dataType === datasourceTypes.DATABRICKS ? "Schema" : "Database Name"}
                                </label>
                                <div className="input-group input-group-merge">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-documents" />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className={"form-control " + (errors.databasename ? " is-invalid" : "")}
                                    id="dataSourceDatabaseLabel"
                                    placeholder="users"
                                    name="databasename"
                                    aria-label={
                                      dataType !== datasourceTypes.DATABRICKS
                                        ? "Enter database name here"
                                        : "Enter the schema name here"
                                    }
                                    ref={register}
                                    defaultValue={databasename}
                                  />
                                  <span className="invalid-feedback">{errors.databasename?.message}</span>
                                </div>
                              </div>
                            </div>
                          )}
                          {(dataType === datasourceTypes.SNOWFLAKE || dataType === datasourceTypes.POSTGRES) && (
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="dataSourceSchemaLabel" className="input-label">
                                  Schema Name
                                </label>
                                <div className="input-group input-group-merge">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-documents" />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className={"form-control " + (errors.schemaname ? " is-invalid" : "")}
                                    disabled={dataType === datasourceTypes.SNOWFLAKE}
                                    id="dataSourceSchemaLabel"
                                    placeholder={dataType === datasourceTypes.POSTGRES ? "public" : "coming soon"}
                                    name="schemaname"
                                    aria-label="Enter schema name here"
                                    ref={register}
                                    defaultValue={schemaname}
                                  />
                                  <span className="invalid-feedback">{errors.schemaname?.message}</span>
                                </div>
                              </div>
                            </div>
                          )}
                          {dataType === datasourceTypes.SNOWFLAKE && (
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="dataSourceWarehouseLabel" className="input-label">
                                  Warehouse Name
                                </label>
                                <div className="input-group input-group-merge">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-documents" />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className={"form-control " + (errors.warehousename ? " is-invalid" : "")}
                                    id="dataSourceSchemaLabel"
                                    placeholder=""
                                    name="warehousename"
                                    aria-label="Enter Data Warehouse name here"
                                    ref={register}
                                    defaultValue={warehousename}
                                  />
                                  <span className="invalid-feedback">{errors.warehousename?.message}</span>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>

                        {dataType === datasourceTypes.MICROSOFT_SQL && (
                          <div className="row">
                            <div className="col-sm-3">
                              <label className="row form-group toggle-switch" htmlFor="encrypt">
                                <span className="col-sm-9 toggle-switch-content ml-0">
                                  <span className="d-block text-dark">Encrypt</span>
                                </span>
                                <span className="col-sm-2">
                                  <input
                                    type="checkbox"
                                    name="encrypt"
                                    className="toggle-switch-input"
                                    id="encrypt"
                                    onChange={handleEncrypt}
                                    ref={register}
                                    checked={encrypt}
                                  />
                                  <span className="toggle-switch-label ml-auto">
                                    <span className="toggle-switch-indicator"></span>
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        )}

                        {((dataType !== datasourceTypes.DATABRICKS && dataType !== datasourceTypes.MICROSOFT_SQL) ||
                          (dataType === datasourceTypes.MICROSOFT_SQL &&
                            (authTypeValue === "default" ||
                              authTypeValue === "azure-active-directory-password" ||
                              authTypeValue === "ntlm"))) && (
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="dataSourceUsernameLabel" className="input-label">
                                  Username
                                </label>
                                <div className="input-group input-group-merge">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-monitor" />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className={"form-control " + (errors.username ? " is-invalid" : "")}
                                    name="username"
                                    id="dataSourceUsernameLabel"
                                    placeholder="admin"
                                    autoComplete="new-username"
                                    ref={register}
                                    defaultValue={username}
                                  />
                                  <span className="invalid-feedback">{errors.username?.message}</span>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="dataSourcePasswordLabel" className="input-label">
                                  Password
                                </label>
                                <div className="input-group input-group-merge">
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-lock" />
                                    </div>
                                  </div>
                                  <input
                                    type="password"
                                    className={"form-control " + (errors.password ? " is-invalid" : "")}
                                    name="password"
                                    id="dataSourcePasswordLabel"
                                    placeholder="password"
                                    autoComplete="new-password"
                                    ref={register}
                                    defaultValue={password}
                                  />
                                  <span className="invalid-feedback">{errors.password?.message}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>

                      {dataType === datasourceTypes.SNOWFLAKE && (
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label htmlFor="dataSourceNewPrivateKeySnowflakeLabel" className="input-label">
                                Private Key{" "}
                                <i
                                  className="tio-help-outlined text-body ml-1"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Copy and paste your private key file contents here"
                                />
                              </label>
                              <div
                                className={
                                  "input-group input-group-merge" + (errors.privatekeysnowflake ? " is-invalid" : "")
                                }
                              >
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-briefcase-outlined" />
                                  </div>
                                </div>
                                <textarea
                                  className={"form-control " + (errors.privatekeysnowflake ? " is-invalid" : "")}
                                  name="privatekeysnowflake"
                                  id="dataSourceNewPrivateKeySnowflakeLabel"
                                  placeholder="Private Key content"
                                  aria-label="Copy and paste your ssh private key file contents here"
                                  ref={register}
                                  defaultValue={privatekeysnowflake}
                                />
                              </div>
                              <small>Copy and paste your private key file contents here</small>
                              <span className="invalid-feedback">{errors.privatekeysnowflake?.message}</span>
                            </div>
                          </div>
                        </div>
                      )}

                      {dataType === datasourceTypes.GOOGLE_BIGQUERY && (
                        <div>
                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label htmlFor="dataSourceNewProjectIdLabel" className="input-label">
                                  Project Id{" "}
                                  <i
                                    className="tio-help-outlined text-body ml-1"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Google project id"
                                  />
                                </label>
                                <div
                                  className={"input-group input-group-merge" + (errors.projectId ? " is-invalid" : "")}
                                >
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-briefcase-outlined" />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className={"form-control " + (errors.projectId ? " is-invalid" : "")}
                                    name="projectId"
                                    id="dataSourceNewProjectIdLabel"
                                    placeholder="Project Id"
                                    aria-label="Enter your google project id here"
                                    ref={register}
                                    defaultValue={googleBigQuery?.projectId}
                                  />
                                </div>
                                <span className="invalid-feedback">{errors.projectId?.message}</span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="dataSourceNewDatasetIdLabel" className="input-label">
                                  Dataset Id{" "}
                                  <i
                                    className="tio-help-outlined text-body ml-1"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Google dataset id"
                                  />
                                </label>
                                <div
                                  className={"input-group input-group-merge" + (errors.datasetId ? " is-invalid" : "")}
                                >
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-briefcase-outlined" />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className={"form-control " + (errors.datasetId ? " is-invalid" : "")}
                                    name="datasetId"
                                    id="dataSourceNewDatasetIdLabel"
                                    placeholder="Dataset Id"
                                    aria-label="Enter your google dataset id here"
                                    ref={register}
                                    defaultValue={googleBigQuery?.datasetId}
                                  />
                                </div>
                                <span className="invalid-feedback">{errors.datasetId?.message}</span>
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="input-label">Region (Location)</label>
                                <div
                                  className={"input-group input-group-merge" + (errors.location ? " is-invalid" : "")}
                                >
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-briefcase-outlined" />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className={"form-control " + (errors.location ? " is-invalid" : "")}
                                    name="location"
                                    placeholder="Enter the region"
                                    aria-label="Enter the region"
                                    ref={register}
                                    defaultValue={googleBigQuery?.location}
                                  />
                                </div>
                                <span className="invalid-feedback">{errors.datasetId?.message}</span>
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label htmlFor="dataSourceNewServiceAccountLabel" className="input-label">
                                  Service Account{" "}
                                  <i
                                    className="tio-help-outlined text-body ml-1"
                                    data-toggle="tooltip"
                                    data-placement="top"
                                    title="Copy and paste the entire service account .json file here"
                                  />
                                </label>
                                <div
                                  className={
                                    "input-group input-group-merge" + (errors.serviceAccount ? " is-invalid" : "")
                                  }
                                >
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-briefcase-outlined" />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className={"form-control " + (errors.serviceAccount ? " is-invalid" : "")}
                                    name="serviceAccount"
                                    id="dataSourceNewServiceAccountLabel"
                                    placeholder="Service Account .json content"
                                    aria-label="Copy and paste the entire service account .json file here"
                                    ref={register}
                                    defaultValue={googleBigQuery?.serviceAccount}
                                  />
                                </div>
                                <small>Copy and paste the entire service account .json file here</small>
                                <span className="invalid-feedback">{errors.serviceAccount?.message}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}

                      <div style={dataType === datasourceTypes.GOOGLE_SHEETS ? {} : { display: "none" }}>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label htmlFor="dataSourceNewSheetUrlLabel" className="input-label">
                                Google Sheet URL{" "}
                                <i
                                  className="tio-help-outlined text-body ml-1"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="From sheets tap Share then Copy Link"
                                />
                              </label>
                              <div className={"input-group input-group-merge" + (errors.sheetUrl ? " is-invalid" : "")}>
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-briefcase-outlined" />
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className={"form-control " + (errors.sheetUrl ? " is-invalid" : "")}
                                  name="sheetUrl"
                                  id="dataSourceNewSheetUrlLabel"
                                  placeholder="Google sheet public URL"
                                  aria-label="Google sheet public URL"
                                  ref={register}
                                  defaultValue={googleSheets?.sheetUrl}
                                />
                              </div>
                              <small>
                                From sheets tap <strong>Share</strong> then <strong>Copy Link</strong>
                              </small>
                              <span className="invalid-feedback">{errors.sheetUrl?.message}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        style={
                          dataType === datasourceTypes.TRINO || type === datasourceTypes.PRESTO
                            ? {}
                            : { display: "none" }
                        }
                      >
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="dataSourceNewCatalogLabel" className="input-label">
                                Catalog
                              </label>
                              <div className={"input-group input-group-merge" + (errors.catalog ? " is-invalid" : "")}>
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-briefcase-outlined" />
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className={"form-control " + (errors.catalog ? " is-invalid" : "")}
                                  name="catalog"
                                  id="dataSourceNewCatalogLabel"
                                  placeholder="Catalog"
                                  aria-label="Enter the catalog here"
                                  ref={register}
                                  defaultValue={trino?.catalog || presto?.catalog}
                                />
                              </div>
                              <span className="invalid-feedback">{errors.catalog?.message}</span>
                            </div>
                          </div>
                          <div className="col-sm-6">
                            <div className="form-group">
                              <label htmlFor="dataSourceNewSchemaLabel" className="input-label">
                                Schema
                              </label>
                              <div className={"input-group input-group-merge" + (errors.schema ? " is-invalid" : "")}>
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-briefcase-outlined" />
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className={"form-control " + (errors.schema ? " is-invalid" : "")}
                                  name="schema"
                                  id="dataSourceNewSchemaLabel"
                                  placeholder="Schema"
                                  aria-label="Enter the schema here"
                                  ref={register}
                                  defaultValue={trino?.schema || presto?.schema}
                                />
                              </div>
                              <span className="invalid-feedback">{errors.schema?.message}</span>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label htmlFor="dataSourceRoleLabel" className="input-label">
                                Role
                              </label>
                              <div className={"input-group input-group-merge" + (errors.role ? " is-invalid" : "")}>
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-briefcase-outlined" />
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className={"form-control " + (errors.role ? " is-invalid" : "")}
                                  name="role"
                                  id="dataSourceRoleLabel"
                                  placeholder="Role"
                                  aria-label="Enter the role here"
                                  ref={register}
                                  defaultValue={trino?.role || presto?.role}
                                />
                              </div>
                              <span className="invalid-feedback">{errors.role?.message}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      {(dataType === datasourceTypes.DATABRICKS ||
                        (dataType === datasourceTypes.MICROSOFT_SQL &&
                          authTypeValue === "azure-active-directory-access-token")) && (
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label htmlFor="dataSourceNewTokenLabel" className="input-label">
                                Token
                              </label>
                              <div className={"input-group input-group-merge" + (errors.token ? " is-invalid" : "")}>
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-lock" />
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className={"form-control " + (errors.token ? " is-invalid" : "")}
                                  name="token"
                                  id="dataSourceNewTokenLabel"
                                  placeholder="token"
                                  aria-label="Enter the token here"
                                  ref={register}
                                  defaultValue={databricks?.token || mssql?.token}
                                />
                              </div>
                              <span className="invalid-feedback">{errors.token?.message}</span>
                            </div>
                          </div>
                        </div>
                      )}

                      {dataType === datasourceTypes.DATABRICKS && (
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label htmlFor="dataSourceNewHttpPathLabel" className="input-label">
                                HTTP Path
                              </label>
                              <div className={"input-group input-group-merge" + (errors.httpPath ? " is-invalid" : "")}>
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-briefcase-outlined" />
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className={"form-control " + (errors.httpPath ? " is-invalid" : "")}
                                  name="httpPath"
                                  id="dataSourceNewHttpPathLabel"
                                  placeholder="/sql/1.0/endpoints/****************"
                                  aria-label="Enter the HTTP path here"
                                  ref={register}
                                  defaultValue={databricks?.httpPath}
                                />
                              </div>
                              <span className="invalid-feedback">{errors.httpPath?.message}</span>
                            </div>
                          </div>
                        </div>
                      )}

                      {DATASOURCES_WITH_SSL_CUSTOMCERTS.includes(dataType) && (
                        <div>
                          <div className="row">
                            <div className="col-sm-4">
                              <label className="row form-group toggle-switch" htmlFor="sslwithcerts">
                                <span className="col-8 col-sm-9 toggle-switch-content ml-0">
                                  <span className="d-block text-dark">Require SSL</span>
                                </span>
                                <span className="col-4 col-sm-3">
                                  <input
                                    type="checkbox"
                                    name="sslwithcerts"
                                    className="toggle-switch-input"
                                    id="sslwithcerts"
                                    checked={sslWithCertsDiv || ssl}
                                    onChange={handleShowHideSSLWithCerts}
                                    ref={register}
                                  />
                                  <span className="toggle-switch-label ml-auto">
                                    <span className="toggle-switch-indicator"></span>
                                  </span>
                                </span>
                              </label>
                            </div>
                          </div>
                        </div>
                      )}

                      <div
                        style={
                          DATASOURCES_WITH_SSL_CUSTOMCERTS.includes(type) && sslWithCertsDiv ? {} : { display: "none" }
                        }
                      >
                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label htmlFor="dataSourceNewsslserverrootcertLabel" className="input-label">
                                Server Root Certificate
                              </label>
                              <div
                                className={
                                  "input-group input-group-merge" + (errors.sslserverrootcert ? " is-invalid" : "")
                                }
                              >
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-briefcase-outlined" />
                                  </div>
                                </div>
                                <textarea
                                  className={"form-control " + (errors.sslserverrootcert ? " is-invalid" : "")}
                                  name="sslserverrootcert"
                                  id="dataSourceNewsslserverrootcertLabel"
                                  placeholder="Server Root Certificate"
                                  aria-label="Copy and paste your ssh server root certificate file contents here"
                                  ref={register}
                                  defaultValue={sslserverrootcert}
                                />
                              </div>
                              <small>Copy and paste your SSL Server root certificate file contents here</small>
                              <span className="invalid-feedback">{errors.sslserverrootcert?.message}</span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label htmlFor="dataSourceNewsslclientkeyLabel" className="input-label">
                                Client Key
                              </label>
                              <div
                                className={"input-group input-group-merge" + (errors.sslclientkey ? " is-invalid" : "")}
                              >
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-briefcase-outlined" />
                                  </div>
                                </div>
                                <textarea
                                  className={"form-control " + (errors.sslclientkey ? " is-invalid" : "")}
                                  name="sslclientkey"
                                  id="dataSourceNewsslclientkeyLabel"
                                  placeholder="Client Key"
                                  aria-label="Copy and paste your ssl client key contents here"
                                  ref={register}
                                  defaultValue={sslclientkey}
                                />
                              </div>
                              <small>Copy and paste your SSL client key file contents here</small>
                              <span className="invalid-feedback">{errors.sslclientkey?.message}</span>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label htmlFor="dataSourceNewsslclientcertLabel" className="input-label">
                                Client Certificate
                              </label>
                              <div
                                className={
                                  "input-group input-group-merge" + (errors.sslclientcert ? " is-invalid" : "")
                                }
                              >
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-briefcase-outlined" />
                                  </div>
                                </div>
                                <textarea
                                  className={"form-control " + (errors.sslclientcert ? " is-invalid" : "")}
                                  name="sslclientcert"
                                  id="dataSourceNewsslserverclientcertLabel"
                                  placeholder="Client Certificate"
                                  aria-label="Copy and paste your ssl client certificate contents here"
                                  ref={register}
                                  defaultValue={sslclientcert}
                                />
                              </div>
                              <small>Copy and paste your SSL client certificate file contents here</small>
                              <span className="invalid-feedback">{errors.sslclientcert?.message}</span>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={DATASOURCES_WITH_SSHTUNNEL.includes(dataType) ? {} : { display: "none" }}>
                        <div className="row">
                          <div className="col-sm-4">
                            <label className="row form-group toggle-switch" htmlFor="sshtunnel">
                              <span className="col-8 col-sm-9 toggle-switch-content ml-0">
                                <span className="d-block text-dark">Require SSH</span>
                              </span>
                              <span className="col-4 col-sm-3">
                                <input
                                  type="checkbox"
                                  name="sshtunnel"
                                  className="toggle-switch-input"
                                  id="sshtunnel"
                                  onChange={handleShowHideSSH}
                                  checked={sshtunnel}
                                  ref={register}
                                />
                                <span className="toggle-switch-label ml-auto">
                                  <span className="toggle-switch-indicator"></span>
                                </span>
                              </span>
                            </label>
                          </div>
                        </div>

                        <div
                          style={
                            DATASOURCES_WITH_SSHTUNNEL.includes(dataType) && sshTunnelDiv ? {} : { display: "none" }
                          }
                        >
                          <div className="col-sm-12">
                            <div className="form-group">
                              <label htmlFor="dataSourceNewSSHPrivateKeyLabel" className="input-label">
                                SSH Private Key{" "}
                                <i
                                  className="tio-help-outlined text-body ml-1"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Copy and paste your ssh private key file contents here"
                                />
                              </label>
                              <div
                                className={
                                  "input-group input-group-merge" + (errors.sshprivatekey ? " is-invalid" : "")
                                }
                              >
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-briefcase-outlined" />
                                  </div>
                                </div>
                                <textarea
                                  className={"form-control " + (errors.sshprivatekey ? " is-invalid" : "")}
                                  name="sshprivatekey"
                                  id="dataSourceNewSSHPrivateKeyLabel"
                                  placeholder="SSH Private Key content"
                                  aria-label="Copy and paste your ssh private key file contents here"
                                  defaultValue={sshprivatekey}
                                  ref={register}
                                />
                              </div>
                              <small>Copy and paste your ssh private key file contents here</small>
                              <span className="invalid-feedback">{errors.sshprivatekey?.message}</span>
                            </div>
                          </div>
                        </div>

                        <div
                          style={
                            DATASOURCES_WITH_SSHTUNNEL.includes(dataType) && sshTunnelDiv ? {} : { display: "none" }
                          }
                        >
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="dataSourceSSHTunnelPortLabel" className="input-label">
                                SSH Port
                              </label>
                              <div className="input-group input-group-merge">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-monitor" />
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className={"form-control " + (errors.sshtunnelport ? " is-invalid" : "")}
                                  name="sshtunnelport"
                                  id="dataSourceSSHTunnelUsernameLabel"
                                  placeholder="ssh-port"
                                  autoComplete="new-sshtunnelport"
                                  ref={register}
                                  defaultValue={sshtunnelport}
                                />
                                <span className="invalid-feedback">{errors.sshtunnelport?.message}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="dataSourceSSHTunnelUsernameLabel" className="input-label">
                                SSH Username
                              </label>
                              <div className="input-group input-group-merge">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-monitor" />
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  className={"form-control " + (errors.sshtunnelusername ? " is-invalid" : "")}
                                  name="sshtunnelusername"
                                  id="dataSourceSSHTunnelUsernameLabel"
                                  placeholder="ssh-user"
                                  autoComplete="new-sshtunnelusername"
                                  ref={register}
                                  defaultValue={sshtunnelusername}
                                />
                                <span className="invalid-feedback">{errors.sshtunnelusername?.message}</span>
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <div className="form-group">
                              <label htmlFor="dataSourceSSHTunnelPasswordLabel" className="input-label">
                                SSH Password
                              </label>
                              <div className="input-group input-group-merge">
                                <div className="input-group-prepend">
                                  <div className="input-group-text">
                                    <i className="tio-lock" />
                                  </div>
                                </div>
                                <input
                                  type="password"
                                  className={"form-control " + (errors.sshtunnelpassword ? " is-invalid" : "")}
                                  name="sshtunnelpassword"
                                  id="dataSourceSSHTunnelPasswordLabel"
                                  placeholder="***"
                                  autoComplete="new-sshtunnelpassword"
                                  ref={register}
                                  defaultValue={sshtunnelpassword}
                                />
                                <span className="invalid-feedback">{errors.sshtunnelpassword?.message}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {dataType === datasourceTypes.MICROSOFT_SQL && (
                        <div className="row">
                          {(authTypeValue === "azure-active-directory-msi-app-service" ||
                            authTypeValue === "azure-active-directory-msi-vm" ||
                            authTypeValue === "azure-active-directory-service-principal-secret") && (
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label htmlFor="dataSourceClientIdLabel" className="input-label">
                                  Client ID
                                </label>
                                <div
                                  className={"input-group input-group-merge" + (errors.clientId ? " is-invalid" : "")}
                                >
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-briefcase-outlined" />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className={"form-control " + (errors.clientId ? " is-invalid" : "")}
                                    name="clientId"
                                    id="dataSourceClientIdLabel"
                                    placeholder="Client Id"
                                    aria-label="Enter your client id here"
                                    ref={register}
                                    defaultValue={clientId}
                                  />
                                </div>
                                <span className="invalid-feedback">{errors.projectId?.message}</span>
                              </div>
                            </div>
                          )}
                          {(authTypeValue === "azure-active-directory-msi-app-service" ||
                            authTypeValue === "azure-active-directory-msi-vm") && (
                            <div
                              className={authTypeValue === "azure-active-directory-msi-vm" ? "col-sm-12" : "col-sm-6"}
                            >
                              <div className="form-group">
                                <label htmlFor="dataSourceMsiEndpointLabel" className="input-label">
                                  MSI Endpoint
                                </label>
                                <div
                                  className={
                                    "input-group input-group-merge" + (errors.msiEndpoint ? " is-invalid" : "")
                                  }
                                >
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-briefcase-outlined" />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className={"form-control " + (errors.msiEndpoint ? " is-invalid" : "")}
                                    name="msiEndpoint"
                                    id="dataSourceMsiEndpointLabel"
                                    placeholder="MSI Endpoint"
                                    aria-label="Enter your MSI Endpoint here"
                                    ref={register}
                                    defaultValue={msiEndpoint}
                                  />
                                </div>
                                <span className="invalid-feedback">{errors.msiEndpoint?.message}</span>
                              </div>
                            </div>
                          )}
                          {authTypeValue === "azure-active-directory-msi-app-service" && (
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label htmlFor="dataSourceMsiSecretLabel" className="input-label">
                                  MSI Secret
                                </label>
                                <div
                                  className={"input-group input-group-merge" + (errors.msiSecret ? " is-invalid" : "")}
                                >
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-briefcase-outlined" />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className={"form-control " + (errors.msiSecret ? " is-invalid" : "")}
                                    name="msiSecret"
                                    id="dataSourceMsiSecretLabel"
                                    placeholder="MSI Secret"
                                    aria-label="Enter your MSI secret here"
                                    ref={register}
                                    defaultValue={msiSecret}
                                  />
                                </div>
                                <span className="invalid-feedback">{errors.msiSecret?.message}</span>
                              </div>
                            </div>
                          )}
                          {(authTypeValue === "azure-active-directory-password" || authTypeValue === "ntlm") && (
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label htmlFor="dataSourceDomainLabel" className="input-label">
                                  Domain
                                </label>
                                <div className={"input-group input-group-merge" + (errors.domain ? " is-invalid" : "")}>
                                  <div className="input-group-prepend">
                                    <div className="input-group-text">
                                      <i className="tio-briefcase-outlined" />
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className={"form-control " + (errors.domain ? " is-invalid" : "")}
                                    name="domain"
                                    id="dataSourceDomainLabel"
                                    placeholder="domain"
                                    aria-label="Enter your domain here"
                                    ref={register}
                                    defaultValue={domain}
                                  />
                                </div>
                                <span className="invalid-feedback">{errors.domain?.message}</span>
                              </div>
                            </div>
                          )}
                          {authTypeValue === "azure-active-directory-service-principal-secret" && (
                            <>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label htmlFor="dataSourceClientSecretLabel" className="input-label">
                                    Client Secret
                                  </label>
                                  <div
                                    className={
                                      "input-group input-group-merge" + (errors.clientSecret ? " is-invalid" : "")
                                    }
                                  >
                                    <div className="input-group-prepend">
                                      <div className="input-group-text">
                                        <i className="tio-briefcase-outlined" />
                                      </div>
                                    </div>
                                    <input
                                      type="text"
                                      className={"form-control " + (errors.clientSecret ? " is-invalid" : "")}
                                      name="clientSecret"
                                      id="dataSourceClientSecretLabel"
                                      placeholder="Client Secret"
                                      aria-label="Enter your client secret here"
                                      ref={register}
                                      defaultValue={clientSecret}
                                    />
                                  </div>
                                  <span className="invalid-feedback">{errors.clientSecret?.message}</span>
                                </div>
                              </div>
                              <div className="col-sm-6">
                                <div className="form-group">
                                  <label htmlFor="dataSourceTenantIdLabel" className="input-label">
                                    Tenant ID
                                  </label>
                                  <div
                                    className={"input-group input-group-merge" + (errors.tenantId ? " is-invalid" : "")}
                                  >
                                    <div className="input-group-prepend">
                                      <div className="input-group-text">
                                        <i className="tio-briefcase-outlined" />
                                      </div>
                                    </div>
                                    <input
                                      type="text"
                                      className={"form-control " + (errors.tenantId ? " is-invalid" : "")}
                                      name="tenantId"
                                      id="dataSourceTenantIdLabel"
                                      placeholder="Tenant ID"
                                      aria-label="Enter your tenant Id here"
                                      ref={register}
                                      defaultValue={tenantId}
                                    />
                                  </div>
                                  <span className="invalid-feedback">{errors.tenantId?.message}</span>
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    <div style={step === 1 ? {} : { display: "none" }}>
                      <DataSourceTables
                        tables={tablesToSelect}
                        includeAll={includeAll}
                        handleIncludeAll={handleIncludeAll}
                        handleInclude={handleInclude}
                      ></DataSourceTables>
                    </div>
                  </form>
                </div>

                <div className="card-footer d-flex justify-content-end align-items-center">
                  <button type="button" className="btn btn-ghost-secondary mb-3 mb-sm-0 mr-2" onClick={cancel}>
                    <i className="tio-chevron-left" /> Cancel
                  </button>

                  {step === 0 ? (
                    <Button variant="primary" type="submit" onClick={handleSubmit(doSave, onError)}>
                      Save <i className="tio-chevron-right"></i>
                    </Button>
                  ) : (
                    ""
                  )}

                  {step === 1 ? (
                    <Button variant="primary" type="button" onClick={updateTables}>
                      Save <i className="tio-chevron-right"></i>
                    </Button>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
};

export default DatasourceForm;
