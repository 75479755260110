export default {
  // login
  FORGOT_PASSWORD_MOUNT: "FORGOT_PASSWORD_MOUNT",
  FORGOT_PASSWORD: "FORGOT_PASSWORD",
  FORGOT_PASSWORD_RESPONSE: "FORGOT_PASSWORD_RESPONSE",
  RESET_PASSWORD_MOUNT: "RESET_PASSWORD_MOUNT",
  RESET_PASSWORD: "RESET_PASSWORD",
  RESET_PASSWORD_RESPONSE: "RESET_PASSWORD_RESPONSE",
  GET_USER_CREDS: "GET_USER_CREDS",
  GET_USER_CREDS_RESPONSE: "GET_USER_CREDS_RESPONSE",
  LOGIN: "LOGIN",
  LOGIN_RESPONSE: "LOGIN_RESPONSE",
  LOGOUT: "LOGOUT",
  LOGOUT_RESPONSE: "LOGOUT_RESPONSE",
  RESET: "RESET",
  RESET_RESPONSE: "RESET_RESPONSE",
  REGISTER: "REGISTER",
  REGISTER_RESPONSE: "REGISTER_RESPONSE",
  LOGIN_GOOGLE_GET_TOKEN: "LOGIN_GOOGLE_GET_TOKEN",
  LOGIN_GOOGLE_GET_TOKEN_RESPONSE: "LOGIN_GOOGLE_GET_TOKEN_RESPONSE",
  VERIFY_EMAIL: "VERIFY_EMAIL",
  VERIFY_EMAIL_RESPONSE: "VERIFY_EMAIL_RESPONSE",
  RESEND_CONFIRMATION: "RESEND_CONFIRMATION",
  RESEND_CONFIRMATION_RESPONSE: "RESEND_CONFIRMATION_RESPONSE",
  CLEAR_TOKENS: "CLEAR_TOKENS",
  LOGIN_WITH_APPLE: "LOGIN_WITH_APPLE",
  LOGIN_WITH_APPLE_RESPONSE: "LOGIN_WITH_APPLE_RESPONSE",
  LOGIN_WITH_SSO: "LOGIN_WITH_SSO",
  LOGIN_WITH_SSO_RESPONSE: "LOGIN_WITH_SSO_RESPONSE",
  VERIFY_LOGIN_WITH_SSO: "VERIFY_LOGIN_WITH_SSO",
  VERIFY_LOGIN_WITH_SSO_RESPONSE: "VERIFY_LOGIN_WITH_SSO_RESPONSE",

  // settings
  SAVE_PASSWORD: "SAVE_PASSWORD",
  SAVE_PASSWORD_RESPONSE: "SAVE_PASSWORD_RESPONSE",
  GET_DETAILS: "GET_DETAILS",
  GET_DETAILS_RESPONSE: "GET_DETAILS_RESPONSE",
  SAVE_DETAILS: "SAVE_DETAILS",
  SAVE_DETAILS_RESPONSE: "SAVE_DETAILS_RESPONSE",
  HIDE_DETAILS_SAVE_SUCCESS: "HIDE_DETAILS_SAVE_SUCCESS",
  HIDE_PASSWORD_SAVE_SUCCESS: "HIDE_PASSWORD_SAVE_SUCCESS",
  SAVE_SETTINGS: "SAVE_SETTINGS",
  SAVE_SETTINGS_RESPONSE: "SAVE_SETTINGS_RESPONSE",
  HIDE_SETTINGS_SAVE_SUCCESS: "HIDE_SETTINGS_SAVE_SUCCESS",
  DELETE_ACCOUNT: "DELETE_ACCOUNT",
  DELETE_ACCOUNT_RESPONSE: "DELETE_ACCOUNT_RESPONSE",
  DELETE_ACCOUNT_HIDE: "DELETE_ACCOUNT_HIDE",
  SAVE_ORGANIZATION_SETTINGS: "SAVE_ORGANIZATION_SETTINGS",
  SAVE_ORGANIZATION_SETTINGS_RESPONSE: "SAVE_ORGANIZATION_SETTINGS_RESPONSE",
  SAVE_PICTURE: "SAVE_PICTURE",
  SAVE_PICTURE_RESPONSE: "SAVE_PICTURE_RESPONSE",
  HIDE_PICTURE_SAVE_SUCCESS: "HIDE_PICTURE_SAVE_SUCCESS",
  USER_CROP_IMAGE_HIDE: "USER_CROP_IMAGE_HIDE",

  // datasource
  DATASOURCE_GET: "DATASOURCE_GET",
  DATASOURCE_GET_RESPONSE: "DATASOURCE_GET_RESPONSE",
  DATASOURCE_GET_FIRST: "DATASOURCE_GET_FIRST",
  DATASOURCE_GET_FIRST_RESPONSE: "DATASOURCE_GET_FIRST_RESPONSE",
  DATASOURCE_GET_BY_ID: "DATASOURCE_GET_BY_ID",
  DATASOURCE_GET_BY_ID_RESPONSE: "DATASOURCE_GET_BY_ID_RESPONSE",
  DATASOURCE_SAVE_SUCCESS_CONFIRM: "DATASOURCE_SAVE_SUCCESS_CONFIRM",
  DATASOURCE_SAVE: "DATASOURCE_SAVE",
  DATASOURCE_SAVE_RESPONSE: "DATASOURCE_SAVE_RESPONSE",
  DATASOURCE_CREATE: "DATASOURCE_CREATE",
  DATASOURCE_CREATE_RESPONSE: "DATASOURCE_CREATE_RESPONSE",
  DATASOURCE_CREATE_HIDE: "DATASOURCE_CREATE_HIDE",
  DATASOURCE_DELETE: "DATASOURCE_DELETE",
  DATASOURCE_DELETE_RESPONSE: "DATASOURCE_DELETE_RESPONSE",
  DATASOURCE_TABLES_SAVE: "DATASOURCE_TABLES_SAVE",
  DATASOURCE_TABLES_SAVE_RESPONSE: "DATASOURCE_TABLES_SAVE_RESPONSE",
  DATASOURCE_GOOGLE_DRIVE_HIDE: "DATASOURCE_GOOGLE_DRIVE_HIDE",
  DATASOURCE_TABLES_RLS: "DATASOURCE_TABLES_RLS",
  DATASOURCE_TABLES_RLS_RESPONSE: "DATASOURCE_TABLES_RLS_RESPONSE",
  DATASOURCE_UPDATE_LIVE_SHEET_TOKENS: "DATASOURCE_UPDATE_LIVE_SHEET_TOKENS",
  DATASOURCE_UPDATE_LIVE_SHEET_TOKENS_RESPONSE: "DATASOURCE_UPDATE_LIVE_SHEET_TOKENS_RESPONSE",
  DATASOURCE_CLEAR_TOKEN_REFRESH_STATUS: "DATASOURCE_CLEAR_TOKEN_REFRESH_STATUS",
  DATASOURCE_UPDATE_RESTRICT_SELECT: "DATASOURCE_UPDATE_RESTRICT_SELECT",
  DATASOURCE_UPDATE_RESTRICT_SELECT_RESPONSE: "DATASOURCE_UPDATE_RESTRICT_SELECT_RESPONSE",
  DATASOURCE_CLEAR_UPDATE_RESTRICT_SELECT_STATUS: "DATASOURCE_CLEAR_UPDATE_RESTRICT_SELECT_STATUS",
  DATASOURCE_CSV_TABLES_DELETE: "DATASOURCE_CSV_TABLES_DELETE",
  DATASOURCE_CSV_TABLES_DELETE_RESPONSE: "DATASOURCE_CSV_TABLES_DELETE_RESPONSE",
  DATASOURCE_CLEAR_CSV_TABLES_DELETE_STATUS: "DATASOURCE_CLEAR_CSV_TABLES_DELETE_STATUS",
  DATASOURCE_GET_FEWSHOTS: "DATASOURCE_GET_FEWSHOTS",
  DATASOURCE_GET_FEWSHOTS_RESPONSE: "DATASOURCE_GET_FEWSHOTS_RESPONSE",
  DATASOURCE_GET_HELPER_TEXT: "DATASOURCE_GET_HELPER_TEXT",
  DATASOURCE_GET_HELPER_TEXT_RESPONSE: "DATASOURCE_GET_HELPER_TEXT_RESPONSE",
  DATASOURCE_GET_FEWSHOT_CATEGORIES: "DATASOURCE_GET_FEWSHOT_CATEGORIES",
  DATASOURCE_GET_FEWSHOT_CATEGORIES_RESPONSE: "DATASOURCE_GET_FEWSHOT_CATEGORIES_RESPONSE",

  // invitation
  INVITATION_GET: "INVITATION_GET",
  INVITATION_GET_RESPONSE: "INVITATION_GET_RESPONSE",
  INVITATION_CREATE: "INVITATION_CREATE",
  INVITATION_CREATE_RESPONSE: "INVITATION_CREATE_RESPONSE",
  INVITATION_CREATE_HIDE: "INVITATION_CREATE_HIDE",
  INVITATION_ACCEPT: "INVITATION_ACCEPT",
  INVITATION_ACCEPT_RESPONSE: "INVITATION_ACCEPT_RESPONSE",
  INVITATION_DELETE: "INVITATION_DELETE",
  INVITATION_DELETE_RESPONSE: "INVITATION_DELETE_RESPONSE",
  INVITATION_RESEND: "INVITATION_RESEND",
  INVITATION_RESEND_RESPONSE: "INVITATION_RESEND_RESPONSE",
  INVITATION_UPDATE_ROLE: "INVITATION_UPDATE_ROLE",
  INVITATION_UPDATE_ROLE_RESPONSE: "INVITATION_UPDATE_ROLE_RESPONSE",
  INVITATION_UPDATE_ROLE_HIDE: "INVITATION_UPDATE_ROLE_HIDE",

  // organization
  ORGANIZATION_OWNER_GET: "ORGANIZATION_OWNER_GET",
  ORGANIZATION_OWNER_GET_RESPONSE: "ORGANIZATION_OWNER_GET_RESPONSE",
  ORGANIZATION_TRANSFER_OWNERSHIP: "ORGANIZATION_TRANSFER_OWNERSHIP",
  ORGANIZATION_TRANSFER_OWNERSHIP_RESPONSE: "ORGANIZATION_TRANSFER_OWNERSHIP_RESPONSE",
  ORGANIZATION_MY_GET: "ORGANIZATION_MY_GET",
  ORGANIZATION_MY_GET_RESPONSE: "ORGANIZATION_MY_GET_RESPONSE",
  ORGANIZATION_CREATE_HIDE: "ORGANIZATION_CREATE_HIDE",
  ORGANIZATION_CREATE: "ORGANIZATION_CREATE",
  ORGANIZATION_CREATE_RESPONSE: "ORGANIZATION_CREATE_RESPONSE",
  ORGANIZATION_GET_BY_ID: "ORGANIZATION_GET_BY_ID",
  ORGANIZATION_GET_BY_ID_RESPONSE: "ORGANIZATION_GET_BY_ID_RESPONSE",
  ORGANIZATION_SAVE: "ORGANIZATION_SAVE",
  ORGANIZATION_SAVE_RESPONSE: "ORGANIZATION_SAVE_RESPONSE",
  ORGANIZATION_DELETE: "ORGANIZATION_DELETE",
  ORGANIZATION_DELETE_RESPONSE: "ORGANIZATION_DELETE_RESPONSE",
  ORGANIZATION_GET_BY_ROLE: "ORGANIZATION_GET_BY_ROLE",
  ORGANIZATION_GET_BY_ROLE_RESPONSE: "ORGANIZATION_GET_BY_ROLE_RESPONSE",
  ORGANIZATION_UPDATE_SAVE_SUCCESS: "ORGANIZATION_UPDATE_SAVE_SUCCESS",
  ORGANIZATION_CLEAR_TRANSFER_STATE: "ORGANIZATION_CLEAR_TRANSFER_STATE",
  ORGANIZATION_SAVE_PERSONALIZATION: "ORGANIZATION_SAVE_PERSONALIZATION",
  ORGANIZATION_SAVE_PERSONALIZATION_RESPONSE: "ORGANIZATION_SAVE_PERSONALIZATION_RESPONSE",
  ORGANIZATION_GET_LOGO: "ORGANIZATION_GET_LOGO",
  ORGANIZATION_GET_LOGO_RESPONSE: "ORGANIZATION_GET_LOGO_RESPONSE",
  ORGANIZATION_CLEAR_SAVE_STATE: "ORGANIZATION_CLEAR_SAVE_STATE",

  // query manager
  QUERY_MANAGER_GET_QUERY: "QUERY_MANAGER_GET_QUERY",
  QUERY_MANAGER_GET_QUERY_RESPONSE: "QUERY_MANAGER_GET_QUERY_RESPONSE",
  QUERY_MANAGER_CHECK_CONNECTION: "QUERY_MANAGER_CHECK_CONNECTION",
  QUERY_MANAGER_CHECK_CONNECTION_RESPONSE: "QUERY_MANAGER_CHECK_CONNECTION_RESPONSE",
  QUERY_MANAGER_START_INGESTION: "QUERY_MANAGER_START_INGESTION",
  QUERY_MANAGER_START_INGESTION_RESPONSE: "QUERY_MANAGER_START_INGESTION_RESPONSE",
  QUERY_MANAGER_GET_QUERY_INGESTION: "QUERY_MANAGER_GET_QUERY_INGESTION",
  QUERY_MANAGER_GET_QUERY_INGESTION_RESPONSE: "QUERY_MANAGER_GET_QUERY_INGESTION_RESPONSE",
  QUERY_MANAGER_START_DB_IMPORTER: "QUERY_MANAGER_START_DB_IMPORTER",
  QUERY_MANAGER_START_DB_IMPORTER_RESPONSE: "QUERY_MANAGER_START_DB_IMPORTER_RESPONSE",
  QUERY_MANAGER_GET_QUERY_DB_IMPORTER: "QUERY_MANAGER_GET_QUERY_DB_IMPORTER",
  QUERY_MANAGER_GET_QUERY_DB_IMPORTER_RESPONSE: "QUERY_MANAGER_GET_QUERY_DB_IMPORTER_RESPONSE",
  QUERY_MANAGER_START_CSV_UPLOAD: "QUERY_MANAGER_START_CSV_UPLOAD",
  QUERY_MANAGER_START_CSV_UPLOAD_RESPONSE: "QUERY_MANAGER_START_CSV_UPLOAD_RESPONSE",
  QUERY_MANAGER_CLEAR_IMPORT_STATE: "QUERY_MANAGER_CLEAR_IMPORT_STATE",
  QUERY_MANAGER_GET_TABLES_AND_COLUMNS: "QUERY_MANAGER_GET_TABLES_AND_COLUMNS",
  QUERY_MANAGER_GET_TABLES_AND_COLUMNS_RESPONSE: "QUERY_MANAGER_GET_TABLES_AND_COLUMNS_RESPONSE",
  QUERY_MANAGER_CLEAR_STATE: "QUERY_MANAGER_CLEAR_STATE",
  QUERY_MANAGER_VALIDATE_JOIN: "QUERY_MANAGER_VALIDATE_JOIN",
  QUERY_MANAGER_VALIDATE_JOIN_RESPONSE: "QUERY_MANAGER_VALIDATE_JOIN_RESPONSE",
  QUERY_MANAGER_START_CSV_IMPORT: "QUERY_MANAGER_START_CSV_IMPORT",
  QUERY_MANAGER_START_CSV_IMPORT_RESPONSE: "QUERY_MANAGER_START_CSV_IMPORT_RESPONSE",
  QUERY_MANAGER_REFRESH_TABLES: "QUERY_MANAGER_REFRESH_TABLES",
  QUERY_MANAGER_REFRESH_TABLES_RESPONSE: "QUERY_MANAGER_REFRESH_TABLES_RESPONSE",

  // public links
  CHARTS_GET_BY_ID: "CHARTS_GET_BY_ID",
  CHARTS_GET_BY_ID_RESPONSE: "CHARTS_GET_BY_ID_RESPONSE",
  PUBLIC_LINKS_GET_BY_ORG: "PUBLIC_LINKS_GET_BY_ORG",
  PUBLIC_LINKS_GET_BY_ORG_RESPONSE: "PUBLIC_LINKS_GET_BY_ORG_RESPONSE",
  PUBLIC_LINKS_DELETE: "PUBLIC_LINKS_DELETE",
  PUBLIC_LINKS_DELETE_RESPONSE: "PUBLIC_LINKS_DELETE_RESPONSE",
  PUBLIC_LINKS_UPDATE_DELETE_SUCCESS: "PUBLIC_LINKS_UPDATE_DELETE_SUCCESS",

  // embeds
  EMBEDS_GET_BY_ORG: "EMBEDS_GET_BY_ORG",
  EMBEDS_GET_BY_ORG_RESPONSE: "EMBEDS_GET_BY_ORG_RESPONSE",
  EMBEDS_GET_API_KEYS_BY_ORG: "EMBEDS_GET_API_KEYS_BY_ORG",
  EMBEDS_GET_API_KEYS_BY_ORG_RESPONSE: "EMBEDS_GET_API_KEYS_BY_ORG_RESPONSE",
  EMBEDS_TOGGLE_EMBED_ID: "EMBEDS_TOGGLE_EMBED_ID",
  EMBEDS_TOGGLE_EMBED_ID_RESPONSE: "EMBEDS_TOGGLE_EMBED_ID_RESPONSE",
  EMBEDS_TOGGLE_API_KEY: "EMBEDS_TOGGLE_API_KEY",
  EMBEDS_TOGGLE_API_KEY_RESPONSE: "EMBEDS_TOGGLE_API_KEY_RESPONSE",
  EMBEDS_UPDATE_EMBED_ID_TOGGLE_SUCCESS: "EMBEDS_UPDATE_EMBED_ID_TOGGLE_SUCCESS",
  EMBEDS_UPDATE_API_KEY_TOGGLE_SUCCESS: "EMBEDS_UPDATE_API_KEY_TOGGLE_SUCCESS",
  EMBEDS_DELETE_API_KEY: "EMBEDS_DELETE_API_KEY",
  EMBEDS_DELETE_API_KEY_RESPONSE: "EMBEDS_DELETE_API_KEY_RESPONSE",
  EMBEDS_UPDATE_DELETE_API_KEY_SUCCESS: "EMBEDS_UPDATE_DELETE_API_KEY_SUCCESS",
  EMBEDS_CREATE_API_KEY: "EMBEDS_CREATE_API_KEY",
  EMBEDS_CREATE_API_KEY_RESPONSE: "EMBEDS_CREATE_API_KEY_RESPONSE",
  EMBEDS_UPDATE_CREATE_API_KEY_SUCCESS: "EMBEDS_UPDATE_CREATE_API_KEY_SUCCESS",
  DASHBOARD_EMBEDS_GET_BY_ORG: "DASHBOARD_EMBEDS_GET_BY_ORG",
  DASHBOARD_EMBEDS_GET_BY_ORG_RESPONSE: "DASHBOARD_EMBEDS_GET_BY_ORG_RESPONSE",

  // joins
  JOINS_CREATE_NEW: "JOINS_CREATE_NEW",
  JOINS_CREATE_NEW_RESPONSE: "JOINS_CREATE_NEW_RESPONSE",
  JOINS_CREATE_HIDE: "JOINS_CREATE_HIDE",
  JOINS_GET_BY_DATASOURCE: "JOINS_GET_BY_DATASOURCE",
  JOINS_GET_BY_DATASOURCE_RESPONSE: "JOINS_GET_BY_DATASOURCE_RESPONSE",
  JOINS_DELETE: "JOINS_DELETE",
  JOINS_DELETE_RESPONSE: "JOINS_DELETE_RESPONSE",
  JOINS_UPDATE_DELETE_SUCCESS: "JOINS_UPDATE_DELETE_SUCCESS",
  JOINS_GET_BY_ID: "JOINS_GET_BY_ID",
  JOINS_GET_BY_ID_RESPONSE: "JOINS_GET_BY_ID_RESPONSE",
  JOINS_UPDATE: "JOINS_UPDATE",
  JOINS_UPDATE_RESPONSE: "JOINS_UPDATE_RESPONSE",
  JOINS_UPDATE_HIDE: "JOINS_UPDATE_HIDE",
  JOINS_CLEAR_SELECTED: "JOINS_CLEAR_SELECTED",

  // users
  USER_IS_ADMIN: "USER_IS_ADMIN",
  USER_IS_ADMIN_RESPONSE: "USER_IS_ADMIN_RESPONSE",

  // account
  ACCOUNTS_PLANS_GET: "ACCOUNTS_PLANS_GET",
  ACCOUNTS_PLANS_GET_RESPONSE: "ACCOUNTS_PLANS_GET_RESPONSE",
  ACCOUNTS_UPGRADE_ORG: "ACCOUNTS_UPGRADE_ORG",
  ACCOUNTS_UPGRADE_ORG_RESPONSE: "ACCOUNTS_UPGRADE_ORG_RESPONSE",
  ACCOUNTS_UPGRADE_CLEAR_STATE: "ACCOUNTS_UPGRADE_CLEAR_STATE",
  ACCOUNTS_BILLING: "ACCOUNTS_BILLING",
  ACCOUNTS_BILLING_RESPONSE: "ACCOUNTS_BILLING_RESPONSE",
  ACCOUNTS_GET_ADMINS_OF_ALL_ORGS: "ACCOUNTS_GET_ADMINS_OF_ALL_ORGS",
  ACCOUNTS_GET_ADMINS_OF_ALL_ORGS_RESPONSE: "ACCOUNTS_GET_ADMINS_OF_ALL_ORGS_RESPONSE",
  ACCOUNT_TRANSFER_OWNERSHIP: "ACCOUNT_TRANSFER_OWNERSHIP",
  ACCOUNT_TRANSFER_OWNERSHIP_RESPONSE: "ACCOUNT_TRANSFER_OWNERSHIP_RESPONSE",

  // fewshots
  FEWSHOTS_CREATE_NEW: "FEWSHOTS_CREATE_NEW",
  FEWSHOTS_CREATE_NEW_RESPONSE: "FEWSHOTS_CREATE_NEW_RESPONSE",
  FEWSHOTS_CLEAR_SUCCESS_STATE: "FEWSHOTS_CLEAR_SUCCESS_STATE",
  FEWSHOTS_UPDATE: "FEWSHOTS_UPDATE",
  FEWSHOTS_UPDATE_RESPONSE: "FEWSHOTS_UPDATE_RESPONSE",
  FEWSHOTS_DELETE: "FEWSHOTS_DELETE",
  FEWSHOTS_DELETE_RESPONSE: "FEWSHOTS_DELETE_RESPONSE",

  // alias
  ALIAS_GET_BY_DATASOURCE: "ALIAS_GET_BY_DATASOURCE",
  ALIAS_GET_BY_DATASOURCE_RESPONSE: "ALIAS_GET_BY_DATASOURCE_RESPONSE",
  ALIAS_CREATE_OR_UPDATE: "ALIAS_CREATE_OR_UPDATE",
  ALIAS_CREATE_OR_UPDATE_RESPONSE: "ALIAS_CREATE_OR_UPDATE_RESPONSE",
  ALIAS_HIDE_SUCCESS_STATE: "ALIAS_HIDE_SUCCESS_STATE",
  ALIAS_DELETE: "ALIAS_DELETE",
  ALIAS_DELETE_RESPONSE: "ALIAS_DELETE_RESPONSE",
  ALIAS_HIDE_DELETE_STATE: "ALIAS_HIDE_DELETE_STATE",

  // fewshots
  HELPER_TEXT_CREATE_NEW: "HELPER_TEXT_CREATE_NEW",
  HELPER_TEXT_CREATE_NEW_RESPONSE: "HELPER_TEXT_CREATE_NEW_RESPONSE",
  HELPER_TEXT_CLEAR_SUCCESS_STATE: "HELPER_TEXT_CLEAR_SUCCESS_STATE",
  HELPER_TEXT_UPDATE: "HELPER_TEXT_UPDATE",
  HELPER_TEXT_UPDATE_RESPONSE: "HELPER_TEXT_UPDATE_RESPONSE",
  HELPER_TEXT_DELETE: "HELPER_TEXT_DELETE",
  HELPER_TEXT_DELETE_RESPONSE: "HELPER_TEXT_DELETE_RESPONSE",
  CATEGORY_CREATE_NEW: "CATEGORY_CREATE_NEW",
  CATEGORY_CREATE_NEW_RESPONSE: "CATEGORY_CREATE_NEW_RESPONSE",
  CATEGORY_CLEAR_SUCCESS_STATE: "CATEGORY_CLEAR_SUCCESS_STATE",
  CATEGORY_UPDATE: "CATEGORY_UPDATE",
  CATEGORY_UPDATE_RESPONSE: "CATEGORY_UPDATE_RESPONSE",
  CATEGORY_DELETE: "CATEGORY_DELETE",
  CATEGORY_DELETE_RESPONSE: "CATEGORY_DELETE_RESPONSE",
};
