import React, { useState, useEffect } from "react";
import { useTable, usePagination, useSortBy } from "react-table";
import styled from "styled-components";
import Loading from "components/loading";

const Select = styled.select`
  font-weight: 400;
  line-height: 1.6;
  vertical-align: middle;
  font-size: 0.8125rem;
  border: none;
`;

function Table(props) {
  const {
    columns,
    data,
    fetchData,
    loading,
    items,
    filtered,
    pageCount: controlledPageCount,
    refresh,
    cancelRefresh,
    setCurrentPage,
    initialPageSize,
    customFilters,
  } = props;

  const [visiblePages, setVisiblePages] = useState([]);
  const [availableSizes, setAvailableSizes] = useState([]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        pageSize: initialPageSize || 10,
        pageIndex: 0,
        sortBy: [],
      },
      manualPagination: true,
      manualSorting: true,
      autoResetPage: false,
      autoResetSortBy: false,
      pageCount: controlledPageCount,
      disableMultiSort: true,
    },
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (refresh) {
      gotoPage(0);
      cancelRefresh();
    }
  }, [refresh]);

  useEffect(() => {
    if (setCurrentPage) {
      setCurrentPage(pageIndex + 1);
    }
  }, [pageIndex, setCurrentPage]);

  useEffect(() => {
    const paginationPages = controlledPageCount; // (controlledPageCount > 5 ? 5 : controlledPageCount);
    setVisiblePages(Array.from({ length: paginationPages }, (_, i) => i + 1));
  }, [data, pageIndex]);

  const sizes = [10, 20, 30, 40, 50];

  useEffect(() => {
    if (items) {
      const filteredSizes = sizes.filter((size) => size < items.total);
      if (filteredSizes.length === 0) {
        setAvailableSizes([items.total]);
      } else {
        setAvailableSizes(filteredSizes);
      }
    }
  }, [items]);

  useEffect(() => {
    let sort;
    if (sortBy.length > 0) {
      const [sortObj] = sortBy;
      const { id, desc } = sortObj;
      sort = `${desc ? "-" : ""}${id}`;
    }

    if (fetchData) {
      fetchData({ pageIndex, pageSize, sort, filtered, customFilters });
    }
  }, [fetchData, pageIndex, pageSize, sortBy, filtered, initialPageSize]);

  return data.length > 0 ? (
    <div>
      <div className="table-responsive datatable-custom">
        <Loading loading={loading}></Loading>

        <table
          className="table table-lg table-borderless table-thead-bordered table-nowrap table-align-middle card-table"
          {...getTableProps()}
        >
          <thead className="thead-light">
            {headerGroups.map((headerGroup, i) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={i}>
                {headerGroup.headers.map((column, index) => {
                  return (
                    <th
                      key={index}
                      {...column.getHeaderProps({
                        style: {
                          minWidth: column.minWidth,
                          maxWidth: column.maxWidth,
                          paddingLeft: column.paddingLeft,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                        },
                      })}
                    >
                      <span {...column.getSortByToggleProps()}>
                        {column.render("Header")}
                        {column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : ""}
                      </span>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()} key={i}>
                  {row.cells.map((cell, cellIndex) => {
                    return (
                      <td
                        {...cell.getCellProps({
                          style: {
                            minWidth: cell.column.minWidth,
                            maxWidth: cell.column.maxWidth,
                            padding: cell.column.padding,
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            whiteSpace: "nowrap",
                          },
                        })}
                        key={cellIndex}
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>

      <div className="card-footer">
        <div className="row justify-content-center justify-content-sm-between align-items-sm-center">
          <div className="col-sm mb-2 mb-sm-0">
            <div className="d-flex justify-content-center justify-content-sm-start align-items-center">
              <span className="mr-1">Showing {data.length} of</span>
              <span id="datatableWithPaginationInfoTotalQty">{items.total}</span>

              <span className="ml-3">Rows:</span>

              <Select
                value={pageSize}
                onChange={(e) => {
                  setPageSize(Number(e.target.value));
                }}
              >
                {availableSizes.map((pageSize, i) => (
                  <option key={i} value={pageSize}>
                    {pageSize}
                  </option>
                ))}
              </Select>
            </div>
          </div>

          <div className="col-sm-auto">
            <div className="d-flex justify-content-center justify-content-sm-end">
              <nav id="datatablePagination" aria-label="Activity pagination">
                <div className="dataTables_paginate paging_simple_numbers" id="datatable_paginate">
                  <ul id="datatable_pagination" className="pagination datatable-custom-pagination">
                    <li className={`paginate_item page-item ${!canPreviousPage ? "disabled" : ""}`}>
                      <a
                        className="paginate_button previous page-link"
                        onClick={() => previousPage()}
                        disabled={!canPreviousPage}
                      >
                        <span aria-hidden="true">Prev</span>
                      </a>
                    </li>

                    {visiblePages.map((page) => {
                      return (
                        <li key={page} className={`paginate_item page-item ${pageIndex + 1 === page ? "active" : ""}`}>
                          <a key={page} className="paginate_button page-link" onClick={() => gotoPage(page - 1)}>
                            {page}
                          </a>
                        </li>
                      );
                    })}

                    <li className={`paginate_item page-item ${!canNextPage ? "disabled" : ""}`}>
                      <a className="paginate_button next page-link" onClick={() => nextPage()} disabled={!canNextPage}>
                        <span aria-hidden="true">Next</span>
                      </a>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </div>
    </div>
  ) : null;
}

export default Table;
