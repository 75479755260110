import Constants from "redux/constants";
import ApiActions from "helpers/api";
import Store from "redux/store";

class EmbedActions {
  static async getQuestionEmbedIdsForOrg(orgId, query) {
    ApiActions.get(
      `/api/v1/embeds/organization/${orgId}`,
      query,
      Store,
      Constants.EMBEDS_GET_BY_ORG,
      Constants.EMBEDS_GET_BY_ORG_RESPONSE
    );
  }

  static async getDashboardEmbedIdsForOrg(orgId, query) {
    ApiActions.get(
      `/api/v1/embeds/organization/${orgId}/dashboard`,
      query,
      Store,
      Constants.DASHBOARD_EMBEDS_GET_BY_ORG,
      Constants.DASHBOARD_EMBEDS_GET_BY_ORG_RESPONSE
    );
  }

  static async getApiKeysForOrg(orgId, query) {
    ApiActions.get(
      `/api/v1/embeds/api-key/organization/${orgId}`,
      query,
      Store,
      Constants.EMBEDS_GET_API_KEYS_BY_ORG,
      Constants.EMBEDS_GET_API_KEYS_BY_ORG_RESPONSE
    );
  }

  static async toggleQuestionEmbedIdStatus(id, data) {
    ApiActions.put(
      `/api/v1/embeds/${id}/toggle`,
      data,
      Store,
      Constants.EMBEDS_TOGGLE_EMBED_ID,
      Constants.EMBEDS_TOGGLE_EMBED_ID_RESPONSE
    );
  }

  static async toggleDashboardEmbedIdStatus(id, data) {
    ApiActions.put(
      `/api/v1/embeds/dashboard/${id}/toggle`,
      data,
      Store,
      Constants.EMBEDS_TOGGLE_EMBED_ID,
      Constants.EMBEDS_TOGGLE_EMBED_ID_RESPONSE
    );
  }

  static async toggleApiKeyStatus(key, data) {
    ApiActions.put(
      `/api/v1/embeds/api-key/${key}/toggle`,
      data,
      Store,
      Constants.EMBEDS_TOGGLE_API_KEY,
      Constants.EMBEDS_TOGGLE_API_KEY_RESPONSE
    );
  }

  static updateEmbedIdToggleSuccess() {
    Store.dispatch({
      type: Constants.EMBEDS_UPDATE_EMBED_ID_TOGGLE_SUCCESS,
    });
  }

  static updateApiKeyToggleSuccess() {
    Store.dispatch({
      type: Constants.EMBEDS_UPDATE_API_KEY_TOGGLE_SUCCESS,
    });
  }

  static async deleteApiKey(key) {
    ApiActions.delete(
      `/api/v1/embeds/api-key/${key}`,
      undefined,
      Store,
      Constants.EMBEDS_DELETE_API_KEY,
      Constants.EMBEDS_DELETE_API_KEY_RESPONSE
    );
  }

  static updateApiKeyDeleteSuccess() {
    Store.dispatch({
      type: Constants.EMBEDS_UPDATE_DELETE_API_KEY_SUCCESS,
    });
  }

  static async createApiKey(data) {
    ApiActions.post(
      "/api/v1/embeds/api-key",
      data,
      Store,
      Constants.EMBEDS_CREATE_API_KEY,
      Constants.EMBEDS_CREATE_API_KEY_RESPONSE
    );
  }

  static updateApiKeyCreateSuccess() {
    Store.dispatch({
      type: Constants.EMBEDS_UPDATE_CREATE_API_KEY_SUCCESS,
    });
  }
}

export default EmbedActions;
