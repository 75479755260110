import React, { useEffect, useState } from "react";
import Select from "react-select";
import { Form } from "react-bootstrap";
import "./examples.scss";

const DynamicFieldsRow = ({ dynamicField, dynamicFields, tableOptions, tables, setDynamicFields }) => {
  const [selectedTable, setSelectedTable] = useState(dynamicField?.table || "");
  const [selectedColumn, setSelectedColumn] = useState(dynamicField?.column || "");
  const [columnOptions, setColumnOptions] = useState();

  useEffect(() => {
    setSelectedTable(dynamicField?.table);
    setSelectedColumn(dynamicField?.column);
  }, [dynamicField?.table, dynamicField?.column]);

  const onClickDelete = () => {
    const existingFields = dynamicFields?.map((f) => ({ ...f }));
    const updatedFields = existingFields.filter((f) => f.name !== dynamicField.name);
    setDynamicFields(updatedFields);
  };

  useEffect(() => {
    const cols = tables.filter((t) => t.tableName === selectedTable).pop()?.columnSchema;
    if (cols?.length) {
      const colOptions = cols.map((c) => ({ label: c.columnName, value: c.columnName }));
      setColumnOptions(colOptions);
    }
  }, [selectedTable, tables]);

  const handleTableChange = (event) => {
    if (event) {
      setSelectedTable(event.value);

      const updatedDynamicFields = dynamicFields.map((f) => {
        if (f.name === dynamicField.name) {
          return {
            ...f,
            table: event.value,
          };
        }

        return f;
      });
      setDynamicFields(updatedDynamicFields);
    } else {
      setSelectedTable("");
    }
  };

  const handleColumnChange = (event) => {
    if (event) {
      setSelectedColumn(event.value);

      const updatedDynamicFields = dynamicFields.map((f) => {
        if (f.name === dynamicField.name && f.table === selectedTable) {
          return {
            ...f,
            column: event.value,
          };
        }

        return f;
      });
      setDynamicFields(updatedDynamicFields);
    } else {
      setSelectedColumn("");
    }
  };

  const onChangeRadio = (e) => {
    const { name, checked } = e.target;

    const updatedDynamicFields = dynamicFields.map((f) => {
      if (f.name === dynamicField.name) {
        return {
          ...f,
          [name]: checked,
        };
      }

      return f;
    });

    setDynamicFields(updatedDynamicFields);
  };

  return (
    <div className="ex-row-container pb-1">
      <div className="col-2 ex-table-data">{dynamicField.name}</div>
      <div className="px-0 d-flex justify-content-center">
        {<img className="img-fluid" src="../../assets/img/link-icon.svg" alt="link table" />}
      </div>
      <div className="col-9 d-flex px-0">
        <div className="col-4 px-0 ex-table-data table-select">
          <Select
            menuPosition={"fixed"}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
              control: (styles) => ({ ...styles, backgroundColor: "#f2f4f6" }),
            }}
            className="w-100 ex-dropdown"
            classNamePrefix="select"
            options={tableOptions}
            onChange={handleTableChange}
            value={tableOptions.find((c) => c.value === selectedTable)}
          />
        </div>
        <div className="col-4 px-0 ex-table-data column-select">
          <Select
            menuPosition={"fixed"}
            styles={{
              menu: (provided) => ({ ...provided, zIndex: 9999 }),
              control: (styles) => ({ ...styles, backgroundColor: "#f2f4f6" }),
            }}
            className="w-100 ex-dropdown"
            classNamePrefix="select"
            options={columnOptions}
            onChange={handleColumnChange}
            value={columnOptions?.find((c) => c.value === selectedColumn)}
          />
        </div>
        <div className="col-3 ex-table-data input-select">
          <Form.Check
            type="checkbox"
            name="required"
            label="Required"
            className="mr-3"
            onChange={onChangeRadio}
            checked={dynamicField.required || false}
          />
          <Form.Check
            type="checkbox"
            name="multiple"
            label="Multiple"
            onChange={onChangeRadio}
            checked={dynamicField.multiple || false}
          />
        </div>
        <div className="col-1 ex-table-data action" onClick={() => onClickDelete()}>
          <img
            className="img-fluid"
            src="../../assets/img/delete-icon.svg"
            alt="delete table"
            style={{ marginLeft: "-6px" }}
          />
        </div>
      </div>
    </div>
  );
};

export default DynamicFieldsRow;
